<template>
    <div class="padding-container">
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <div class="row">
                <div
                    class="col-lg-12"
                    v-for="(account, index) in accounts"
                    :key="index"
                >
                    <div class="d-flex justify-content-between">
                        <h5 class="fw-bold">Akun User {{ index + 1 }}</h5>
                        <button
                            class="btn btn-delete d-flex align-items-center outline-green"
                            v-if="accounts.length > 1 && index >= 1"
                            @click="prepareRemoveAccount(index)"
                        >
                            <img
                                src="../assets/delete.svg"
                                alt="trash"
                                class="me-2"
                            />
                            Hapus Bagian
                        </button>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 my-2">
                            <label class="fw-bold mb-2"
                                >Peran <span class="text-yellow">*</span></label
                            >
                            <multiselect
                                v-model="account.role"
                                :options="roles"
                                label="role"
                                track-by="role"
                                placeholder="Pilih peran user"
                                class="multi-form-custom"
                                :show-labels="false"
                                :disabled="index !== 0"
                            >
                            </multiselect>
                        </div>
                        <div class="col-lg-6 my-2">
                            <label class="fw-bold mb-2"
                                >Nama <span class="text-yellow">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="account.name"
                                placeholder="Masukkan nama"
                            />
                        </div>
                        <div class="col-lg-6 my-2">
                            <label class="fw-bold mb-2">
                                Nomor Handphone
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <div class="input-group mb-0">
                                <span class="input-group-text">+62</span>
                                <input
                                    type="text"
                                    class="form-control form-phone"
                                    placeholder="Masukkan nomor"
                                    @input="validatePhone(account)"
                                    v-model="account.phone"
                                />
                            </div>
                            <div
                                v-if="account.phoneError"
                                class="d-flex align-items-center mt-2"
                            >
                                <img
                                    src="../assets/mini-warning.svg"
                                    alt="mini warning"
                                    class="me-2"
                                />
                                <small class="text-yellow">{{
                                    account.phoneError
                                }}</small>
                            </div>
                        </div>
                        <div class="col-lg-6 my-2">
                            <label class="fw-bold mb-2"
                                >Email <span class="text-yellow">*</span></label
                            >
                            <input
                                type="email"
                                class="form-control"
                                v-model="account.email"
                                placeholder="Masukkan email"
                                @input="validateEmail(account)"
                            />
                            <div
                                v-if="account.emailError"
                                class="d-flex align-items-center mt-2"
                            >
                                <img
                                    src="../assets/mini-warning.svg"
                                    alt="mini warning"
                                    class="me-2"
                                />
                                <small class="text-yellow">{{
                                    account.emailError
                                }}</small>
                            </div>
                        </div>
                        <div class="col-lg-6 my-2">
                            <label class="fw-bold mb-2"
                                >Password Akun
                                <span class="text-yellow">*</span></label
                            >
                            <div class="password-container">
                                <input
                                    :type="
                                        account.showPassword
                                            ? 'text'
                                            : 'password'
                                    "
                                    class="form-control"
                                    v-model="account.password"
                                    placeholder="Buat password akun"
                                    @input="validatePassword(account)"
                                />
                                <div class="eye-icons">
                                    <img
                                        :src="
                                            account.showPassword
                                                ? eyeShowIcon
                                                : eyeHideIcon
                                        "
                                        :alt="
                                            account.showPassword
                                                ? 'eye show'
                                                : 'eye hide'
                                        "
                                        @click="
                                            () =>
                                                togglePasswordVisibility(
                                                    account
                                                )
                                        "
                                        class="eye-icon"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="account.passwordError"
                                class="d-flex align-items-center mt-2"
                            >
                                <img
                                    src="../assets/mini-warning.svg"
                                    alt="mini warning"
                                    class="me-2"
                                />
                                <small class="text-yellow">{{
                                    account.passwordError
                                }}</small>
                            </div>
                        </div>
                        <div class="col-lg-6 my-2">
                            <label class="fw-bold mb-2"
                                >Konfirmasi Password Akun
                                <span class="text-yellow">*</span></label
                            >
                            <div class="password-container">
                                <input
                                    :type="
                                        account.showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                    "
                                    class="form-control"
                                    v-model="account.passwordConfirmation"
                                    placeholder="Konfirmasi password akun"
                                    @input="
                                        validatePasswordConfirmation(account)
                                    "
                                />
                                <div class="eye-icons">
                                    <img
                                        :src="
                                            account.showConfirmPassword
                                                ? eyeShowIcon
                                                : eyeHideIcon
                                        "
                                        :alt="
                                            account.showConfirmPassword
                                                ? 'eye show'
                                                : 'eye hide'
                                        "
                                        @click="
                                            () =>
                                                toggleConfirmPasswordVisibility(
                                                    account
                                                )
                                        "
                                        class="eye-icon"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="account.passwordConfirmationError"
                                class="d-flex align-items-center mt-2"
                            >
                                <img
                                    src="../assets/mini-warning.svg"
                                    alt="mini warning"
                                    class="me-2"
                                />
                                <small class="text-yellow">{{
                                    account.passwordConfirmationError
                                }}</small>
                            </div>
                        </div>
                        <div class="col-lg-6 my-2">
                            <label class="fw-bold mb-2"
                                >Cabang
                                <span class="text-yellow">*</span></label
                            >
                            <multiselect
                                v-model="account.branch"
                                :options="branches"
                                label="name"
                                track-by="id"
                                placeholder="Pilih cabang"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-lg-12 my-2">
                            <div class="row">
                                <div class="col-lg-3">
                                    <label class="fw-bold mb-2"
                                        >Foto Profile</label
                                    >
                                    <div
                                        class="box d-flex flex-column justify-content-center py-5 position-relative cursor-pointer h-14"
                                        :class="{
                                            'padding-0': account.profileImage,
                                        }"
                                        @click="triggerFileInput(index)"
                                    >
                                        <input
                                            type="file"
                                            :ref="`fileInput${index}`"
                                            @change="
                                                handleFileUpload(index, $event)
                                            "
                                            accept="image/*"
                                            class="d-none"
                                        />
                                        <div
                                            v-if="!account.profileImage"
                                            class="text-center"
                                        >
                                            <img
                                                src="../assets/upload-profile.svg"
                                                alt="upload profile"
                                            />
                                            <p
                                                class="text-gray text-center mb-0"
                                            >
                                                Upload foto profile
                                            </p>
                                        </div>
                                        <div
                                            v-else
                                            class="profile-image-preview"
                                        >
                                            <img
                                                :src="account.profileImage"
                                                alt="Profile Preview"
                                                class="img-fluid rounded border-1-rem"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="my-5" />
                    </div>
                </div>
                <div class="col-lg-12 my-2">
                    <button
                        v-if="
                            accounts.length < maxAccountLimit ||
                            maxAccountLimit === 0
                        "
                        class="btn w-100 btn-add d-flex align-items-center justify-content-center"
                        @click="addAccount"
                        :disabled="
                            !isFormValid || accounts.length >= maxAccountLimit
                        "
                    >
                        <i class="fa fa-plus me-2"></i>
                        Tambah Akun User
                    </button>
                    <button
                        v-else
                        class="btn w-100 btn-add-disabled d-flex align-items-center justify-content-center"
                        @click="addLimit = true"
                    >
                        <i class="fa fa-plus me-2"></i>
                        Tambah Akun User
                    </button>
                </div>
                <div class="col-lg-12 my-2 d-flex justify-content-end mt-4">
                    <button
                        class="btn btn-create px-5"
                        :disabled="!isFormValid || isSave"
                        @click="createAccount = true"
                    >
                        Buat Akun User
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="addLimit">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Limit akun untuk peran ini sudah tercapai
                    </h5>
                </div>
                <button
                    @click="addLimit = false"
                    type="button"
                    class="btn btn-primary w-100 btn-lg mt-4"
                >
                    Tutup
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="deleteSection">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Informasi Akun Ini Akan Dihapus
                    </h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus informasi ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <button
                            class="btn btn-outline-yellow w-100 btn-lg"
                            @click="deleteSection = false"
                        >
                            Kembali
                        </button>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="removeAccount()"
                        >
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="createAccount">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        {{ accounts.length }} akun
                        {{ accounts[0].role.role }} akan dibuat
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <button
                            class="btn btn-outline-yellow w-100 btn-lg"
                            @click="createAccount = false"
                        >
                            Kembali
                        </button>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isSave"
                            @click="storeData()"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isSave"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="createSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Akun User Berhasil Dibuat</h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/user-management"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="deleteSectionModal">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Hapus {{ sectionsToRemove }} akun bagian sebelum
                        melanjutkan atau pilih peran lain
                    </h5>
                </div>
                <button
                    @click="deleteSectionModal = false"
                    type="button"
                    class="btn btn-primary w-100 btn-lg mt-4"
                >
                    Tutup
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import eyeHideIcon from "@/assets/eye-hide.svg";
import eyeShowIcon from "@/assets/eye-show.svg";
import Api from "../api/Api";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            accounts: [
                {
                    role: null,
                    name: "",
                    phone: "",
                    phoneError: "",
                    email: "",
                    emailError: "",
                    password: "",
                    passwordError: "",
                    passwordConfirmation: "",
                    passwordConfirmationError: "",
                    showPassword: false,
                    showConfirmPassword: false,
                    profileImage: null,
                    avatarUrl: null,
                    branch: null,
                },
            ],
            eyeHideIcon,
            eyeShowIcon,
            roles: [],
            addLimit: false,
            deleteSection: false,
            createAccount: false,
            createSuccess: false,
            checkEmail: false,
            checkPhone: false,
            maxAccountLimit: 0,
            deleteSectionModal: false,
            sectionsToRemove: 0,
            isSave: false,
            accountToRemoveIndex: null,
            branches: [],
        };
    },
    computed: {
        isFormValid() {
            return (
                this.accounts.length <= this.maxAccountLimit &&
                this.accounts.every(
                    (account) =>
                        account.role !== null &&
                        account.name.trim() !== "" &&
                        account.email.trim() !== "" &&
                        account.phone.trim() !== "" &&
                        account.phoneError === "" &&
                        account.emailError === "" &&
                        account.password.trim() !== "" &&
                        account.passwordError === "" &&
                        account.passwordConfirmation.trim() !== "" &&
                        account.passwordConfirmationError === "" &&
                        account.branch !== null
                )
            );
        },
    },
    created() {
        this.getRoles();
        this.getBranches();
    },
    methods: {
        prepareRemoveAccount(index) {
            this.accountToRemoveIndex = index;
            this.deleteSection = true;
        },
        handleRoleChange(newRole) {
            if (newRole) {
                const roleLimit =
                    this.roles.find((role) => role.role === newRole.role)
                        ?.limit || 0;

                if (this.accounts.length > roleLimit) {
                    this.accounts[0].role = this.accounts[1].role;
                    this.sectionsToRemove = this.accounts.length - roleLimit;
                    this.deleteSectionModal = true;
                } else {
                    this.maxAccountLimit = roleLimit;
                    this.accounts.forEach((account) => {
                        account.role = newRole;
                    });
                }
            }
        },
        confirmRoleChange() {
            this.accounts.splice(this.maxAccountLimit);

            const newRole = this.accounts[0].role;
            this.accounts.forEach((account) => {
                account.role = newRole;
            });

            this.deleteSectionModal = false;
        },
        checkEmailAvailability(account) {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/user_management/check/email`,
                {
                    params: { email: account.email },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    if (res.data.data === true) {
                        account.emailError = "Email sudah terdaftar";
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        checkPhoneAvailability(account) {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/user_management/check/phone`,
                {
                    params: { phone: account.phone },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    if (res.data.data === true) {
                        account.phoneError = "Nomor handphone sudah terdaftar";
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getRoles() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/user_management/limit`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.roles = res.data.data.reduce(
                        (filteredRoles, currentRole) => {
                            if (currentRole.limit > 0) {
                                filteredRoles.push(currentRole);
                            }
                            return filteredRoles;
                        },
                        []
                    );
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getBranches() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/branch`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.branches = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        validateEmail(account) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(account.email)) {
                account.emailError = "Format email salah";
            } else {
                account.emailError = "";

                const isDuplicateEmail =
                    this.accounts.filter(
                        (acc) =>
                            acc.email.toLowerCase() ===
                            account.email.toLowerCase()
                    ).length > 1;

                if (isDuplicateEmail) {
                    account.emailError = "Email sudah terdaftar";
                }

                if (!account.emailError) {
                    this.checkEmailAvailability(account);
                }
            }
        },
        validatePhone(account) {
            if (!account.phone.startsWith("8")) {
                account.phone = "8" + account.phone.replace(/[^0-9]/g, "");
            } else {
                account.phone = account.phone.replace(/[^0-9]/g, "");
            }

            const isDuplicatePhone =
                this.accounts.filter((acc) => acc.phone === account.phone)
                    .length > 1;

            if (isDuplicatePhone) {
                account.phoneError = "Nomor handphone sudah terdaftar";
            } else {
                account.phoneError = "";
                this.checkPhoneAvailability(account);
            }
        },
        validatePassword(account) {
            if (account.password.length < 6) {
                account.passwordError =
                    "Password harus terdiri dari min. 6 karakter";
            } else {
                account.passwordError = "";
            }
            if (account.passwordConfirmation) {
                this.validatePasswordConfirmation(account);
            }
        },
        validatePasswordConfirmation(account) {
            if (account.password !== account.passwordConfirmation) {
                account.passwordConfirmationError = "Password tidak sesuai";
            } else {
                account.passwordConfirmationError = "";
            }
        },
        togglePasswordVisibility(account) {
            account.showPassword = !account.showPassword;
        },
        toggleConfirmPasswordVisibility(account) {
            account.showConfirmPassword = !account.showConfirmPassword;
        },
        triggerFileInput(index) {
            this.$refs[`fileInput${index}`][0].click();
        },
        handleFileUpload(index, event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                this.accounts[index].profileImage = e.target.result;

                this.uploadAvatar(index, file);
            };
            reader.readAsDataURL(file);
        },
        addAccount() {
            if (this.accounts.length >= this.maxAccountLimit) {
                this.addLimit = true;
                return;
            }

            const firstAccountRole = this.accounts[0].role;

            const roleLimit =
                this.roles.find((role) => role.role === firstAccountRole.role)
                    ?.limit || 0;

            this.maxAccountLimit = roleLimit;

            this.accounts.push({
                role: firstAccountRole,
                name: "",
                phone: "",
                email: "",
                emailError: "",
                password: "",
                passwordError: "",
                passwordConfirmation: "",
                passwordConfirmationError: "",
                showPassword: false,
                showConfirmPassword: false,
                profileImage: null,
                branch: null,
            });
        },
        removeAccount() {
            if (this.accountToRemoveIndex !== null) {
                this.accounts.splice(this.accountToRemoveIndex, 1);

                this.deleteSection = false;
                this.accountToRemoveIndex = null;
            }
        },
        uploadAvatar(index, file) {
            const formData = new FormData();
            formData.append("path", "user_avatar");
            formData.append("file", file);

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/general/upload`,
                formData,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
                .then((res) => {
                    this.accounts[index].avatarUrl = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        storeData() {
            this.isSave = true;
            const itemsData = this.accounts.map((item) => ({
                role_id: item.role.role,
                name: item.name,
                phone: item.phone,
                email: item.email,
                password: item.password,
                password_confirmation: item.passwordConfirmation,
                avatar: item.avatarUrl,
                branch_id: item.branch.id,
            }));

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/user_management/create/bulk`,
                itemsData,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isSave = false;
                    this.createSuccess = true;
                    this.createAccount = false;
                })
                .catch((err) => {
                    this.isSave = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
    watch: {
        "accounts.0.role"(newRole) {
            this.handleRoleChange(newRole);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .multiselect--disabled .multiselect__tags,
::v-deep .multiselect--disabled .multiselect__tags .multiselect__single {
    background: #ededed !important;
}
.outline-green {
    border: 1px solid #ff8600 !important;
    color: #ff8600 !important;
    background-color: transparent !important;
    border-radius: 8px;
}
.border-1-rem {
    border-radius: 1rem !important;
}
hr {
    color: #c1cdd7 !important;
}
.h-14 {
    height: 14rem;
}
.padding-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.profile-image-preview {
    position: relative;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .change-photo-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        text-align: center;
        padding: 10px;
        cursor: pointer;
    }
}
.btn-create {
    background-color: #1291ff;
    color: #ffffff;
}
.btn-create:hover {
    background-color: #1291ff;
    color: #ffffff;
}
.btn-create:disabled {
    background-color: #849bae;
    color: #ffffff;
    cursor: not-allowed;
}
.btn-add {
    background-color: transparent;
    border: 1px solid #40bf76;
    color: #40bf76;
}
.btn-add-disabled {
    background-color: transparent;
    border: 1px solid #849bae;
    color: #849bae;
}
.btn-add-disabled:hover {
    background-color: transparent;
    border: 1px solid #849bae;
    color: #849bae;
}
.btn-add:disabled {
    background-color: transparent !important;
    border: 1px solid #849bae !important;
    color: #849bae !important;
}
.btn-add:hover {
    background-color: transparent;
    border: 1px solid #849bae;
    color: #849bae;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.password-container {
    position: relative;
    .eye-icons {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    .eye-icon {
        width: 20px;
        height: 20px;
    }
    input {
        padding-right: 40px;
    }
}
</style>

<template>
    <div class="padding-container">
        <div class="box-manifest">
            <h5 class="fw-bold">Daftar Pricing</h5>
            <div class="search-filter-container">
                <div
                    class="d-flex align-items-center no-flex mt-4 search-filter-wrapper"
                >
                    <div class="search-box me-4 m-mx-0 m-mt-2">
                        <div class="position-relative">
                            <div class="input-group">
                                <input
                                    type="text"
                                    class="form-control form-control-lg pl-search w-search border-search"
                                    placeholder="Cari kota asal/tujuan"
                                    v-model="search"
                                    @keyup.enter="searchPricings()"
                                />
                                <button
                                    class="btn btn-primary px-4 search-button"
                                    type="button"
                                    @click="searchPricings()"
                                >
                                    Cari
                                </button>
                            </div>
                            <span class="search-icon left">
                                <i class="pi pi-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="filter-button m-mx-0 m-mt-2 me-3">
                        <button
                            class="btn btn-outline-yellow btn-filter px-3"
                            @click="isFilter = !isFilter"
                        >
                            <img
                                src="../assets/dashboard/filter.png"
                                class="me-1"
                                width="18"
                                alt=""
                            />
                            Filter
                        </button>
                    </div>
                </div>
                <div
                    class="box mt-3 position-relative filter-box"
                    v-if="isFilter"
                >
                    <h5>Filter</h5>
                    <div class="row">
                        <div class="col-lg-12">
                            <label class="fw-bold mb-2">Jenis Layanan</label>
                            <div class="row filter-content">
                                <div class="col-lg-8 my-2">
                                    <multiselect
                                        v-model="service"
                                        :options="services"
                                        label="name"
                                        track-by="id"
                                        placeholder="Pilih jenis layanan"
                                        class="multi-form-custom"
                                        :show-labels="false"
                                    >
                                    </multiselect>
                                </div>
                                <div class="col-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6 my-2">
                                            <button
                                                class="btn btn-outline-yellow me-3 w-100"
                                                :disabled="service == null"
                                                @click="clearFilter()"
                                            >
                                                Clear
                                            </button>
                                        </div>
                                        <div class="col-lg-6 my-2">
                                            <button
                                                class="btn btn-green w-100"
                                                :disabled="service == null"
                                                @click="applyFilter()"
                                            >
                                                Filter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center mt-4 pagination-wrapper">
                    <div class="pagination-info">
                        Menampilkan
                        <span class="fw-bold"
                            >{{ pageStart }}-{{ pageEnd }}</span
                        >
                        Data dari
                        <span class="fw-bold">{{ pricings.total_data }}</span>
                        Data
                    </div>
                    <div class="pagination-select ms-auto">
                        <div class="d-flex align-items-center">
                            <div class="me-2">Baris per halaman</div>
                            <select
                                class="form-control form-select w-select outline-yellow"
                                v-model="limit"
                                @change="onLimitChange"
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="isLoader">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Tujuan
                                </th>
                                <th class="text-center" scope="col">
                                    Jenis Layanan
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="isRole(action)"
                                >
                                    Harga Pengiriman
                                    <img
                                        src="../assets/sort.svg"
                                        alt="sort"
                                        class="ms-2 cursor-pointer"
                                        @click="handleSort()"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(pricing, index) in pricings.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>{{ pricing.regency }}</td>
                                <td>{{ pricing.dooring }}</td>
                                <td>
                                    <p
                                        class="rounded-pill service text-center mb-0 px-3"
                                    >
                                        {{
                                            pricing.service_type
                                                .replace(/_/g, " ")
                                                .replace(/\b\w/g, (char) =>
                                                    char.toUpperCase()
                                                )
                                        }}
                                    </p>
                                </td>
                                <td v-if="isRole(action)">
                                    <div
                                        class="d-flex justify-content-end align-items-center cursor-pointer"
                                        @click="openSidebar(pricing.id)"
                                    >
                                        <p class="text-link mb-0 me-2">
                                            {{
                                                currency(pricing.service_price)
                                            }}
                                        </p>
                                        <img
                                            src="../assets/edit.svg"
                                            width="20"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    class="text-center mt-5"
                    v-if="pricings.list_data.length === 0"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar pricing
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="pricings.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getPricings()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
        <div
            class="sidebar-overlay"
            v-if="isSidebarOpen"
            @click="closeSidebar()"
        ></div>
        <div
            class="sidebar"
            v-if="isSidebarOpen"
            :class="{ 'sidebar-open': isSidebarOpen }"
        >
            <div
                class="d-flex justify-content-between align-items-center p-3 border-bottom sticky-header"
            >
                <h5 class="mb-0 fw-bold">Edit Pricing</h5>
                <div
                    type="button"
                    class="btn-close"
                    @click="closeSidebar()"
                ></div>
            </div>
            <div class="p-3 mb-5-rem sidebar-body">
                <label class="form-label"
                    >Harga Pengiriman <span class="text-yellow">*</span></label
                >
                <div class="input-group mb-3">
                    <span class="input-group-text">Rp</span>
                    <input
                        type="text"
                        class="form-control input-sidebar"
                        placeholder="Masukkan harga pengiriman"
                        v-model="formattedPrice"
                    />
                </div>
                <hr />
                <h5 class="mb-3 fw-bold">Data Pricing Vendor</h5>
                <div v-if="isLoading">
                    <div class="d-flex justify-content-center mt-5">
                        <img src="../assets/empty.svg" alt="empty" />
                    </div>
                    <p class="text-gray text-center mt-5">
                        Sedang mengambil data
                    </p>
                </div>
                <div v-else-if="detailPricing.transit_lists.length === 0">
                    <div class="d-flex justify-content-center mt-5">
                        <img src="../assets/empty.svg" alt="empty" />
                    </div>
                    <p class="text-gray text-center mt-5">
                        Tidak ditemukan data pricing dari vendor
                    </p>
                </div>
                <div
                    class="mb-4"
                    v-for="(transit_list, index) in detailPricing.transit_lists"
                    :key="index"
                >
                    <div
                        class="d-flex justify-content-between header-sidebar px-3 py-2"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                src="../assets/origin-location.svg"
                                alt="origin location"
                                class="me-2"
                            />
                            {{ transit_list.origin }}
                        </div>
                        <img
                            src="../assets/arrow-location.svg"
                            alt="arrow location"
                        />
                        <div class="d-flex align-items-center">
                            <img
                                src="../assets/destination-location.svg"
                                alt="destination location"
                                class="me-2"
                            />
                            {{ transit_list.destination }}
                        </div>
                    </div>
                    <div class="body-sidebar px-3 py-2">
                        <div
                            class="d-flex justify-content-between align-items-center my-2"
                            v-for="(vendor, index) in transit_list.vendors"
                            :key="index"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    :src="
                                        vendor.vendor_type === 'darat'
                                            ? require('../assets/truck-passive.svg')
                                            : vendor.vendor_type === 'laut'
                                            ? require('../assets/ship-passive.svg')
                                            : vendor.vendor_type === 'udara'
                                            ? require('../assets/plane-passive.svg')
                                            : ''
                                    "
                                    alt="vendor location"
                                    class="me-2"
                                />

                                <p class="mb-0">{{ vendor.vendor_name }}</p>
                            </div>
                            <p class="text-primary mb-0">
                                {{ currency(vendor.price) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3 sticky-footer">
                <button
                    class="btn btn-primary w-100"
                    :disabled="
                        detailPricing.price === null ||
                        detailPricing.price === ''
                    "
                    @click="openModalConfirm(detailPricing.route_id)"
                >
                    Simpan Data
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data pricing akan disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="editPricing()"
                            :disabled="isLoadingEdit"
                        >
                            <span
                                v-if="isLoadingEdit"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Data pricing sudah disimpan
                    </h5>
                </div>
                <div class="mt-4">
                    <div
                        class="btn btn-primary w-100 btn-lg"
                        @click="
                            isSidebarOpen = false;
                            modalSuccess = false;
                            getPricings();
                        "
                    >
                        Tutup
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";

export default {
    components: {
        Multiselect,
        ListLoader,
    },
    props: ["roles"],
    data() {
        return {
            sortDirection: null,
            modalConfirm: false,
            modalSuccess: false,
            isSidebarOpen: false,
            pageStart: 0,
            pageEnd: 0,
            limit: 5,
            totalPage: 0,
            currentPage: 1,
            isFilter: false,
            search: null,
            service: null,
            services: [
                {
                    name: "Pengiriman Barang",
                    id: "pengiriman_barang",
                },
                {
                    name: "Pengiriman Motor",
                    id: "pengiriman_motor",
                },
                {
                    name: "Pengiriman Mobil",
                    id: "pengiriman_mobil",
                },
                {
                    name: "Sewa Kontainer 20 Feet",
                    id: "sewa_container_20ft",
                },
                {
                    name: "Sewa Kontainer 40 Feet",
                    id: "sewa_container_40ft",
                },
            ],
            isLoader: false,
            pricings: {
                list_data: [],
            },
            detailPricing: {
                transit_lists: [],
                route_id: null,
                price: null,
            },
            isLoading: false,
            editId: null,
            isLoadingEdit: false,
            formattedPrice: "",
            action: ["super-admin", "finance"],
        };
    },
    watch: {
        formattedPrice(newVal) {
            const numericValue = newVal.replace(/\D/g, "");
            this.detailPricing.price = numericValue;

            if (numericValue) {
                this.formattedPrice = this.formatNumber(numericValue);
            }
        },
        "detailPricing.price"(newVal) {
            if (newVal && !this.formattedPrice) {
                this.formattedPrice = this.formatNumber(newVal);
            }
        },
    },
    created() {
        this.getPricings();
    },
    methods: {
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        formatNumber(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        searchPricings() {
            this.currentPage = 1;
            this.getPricings();
        },
        openModalConfirm(value) {
            this.modalConfirm = true;
            this.editId = value;
        },
        handleSort() {
            this.currentPage = 1;
            this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
            this.getPricings();
        },
        openSidebar(value) {
            this.isSidebarOpen = true;
            this.formattedPrice = "";
            this.getDetailPricing(value);
        },
        closeSidebar() {
            this.isSidebarOpen = false;
            this.detailPricing = {
                transit_lists: [],
                route_id: null,
                price: null,
            };
            this.formattedPrice = "";
        },
        onLimitChange() {
            this.currentPage = 1;
            this.getPricings();
        },
        clearFilter() {
            this.service = null;
            this.getPricings();
        },
        applyFilter() {
            this.currentPage = 1;
            this.getPricings();
        },
        getPricings() {
            this.isLoader = true;
            let payload = {
                page: this.currentPage,
                search: this.search ? this.search : null,
                limit: this.limit ? this.limit : null,
                service: this.service ? this.service.id : null,
                sort: this.sortDirection,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/ho/pricing`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.isLoader = false;
                    this.pricings = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.pricings.list_data.length;
                    const totalData = res.data.data.total_data;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        Number(this.pageStart) + Number(this.limit) - 1,
                        totalData
                    );
                })
                .catch((err) => {
                    this.isLoader = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDetailPricing(value) {
            this.isLoading = true;
            let payload = {
                route_id: value,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/ho/pricing/detail`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.isLoading = false;
                    this.detailPricing = res.data.data;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        editPricing() {
            this.isLoadingEdit = true;
            var data = {
                route_id: this.editId,
                price: this.detailPricing.price,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/ho/pricing/update`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoadingEdit = false;
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.isLoadingEdit = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.mb-5-rem {
    margin-bottom: 10rem;
}
.search-filter-container {
    width: 100%;
}

.w-search {
    width: 100%;
}

.w-select {
    width: 80px;
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: #f9fafb;
    z-index: 1;
}
.sticky-footer {
    position: fixed;
    bottom: 0;
    background: #f9fafb;
    width: 100%;
    max-width: 500px;
    padding: 1rem;
    border-top: 1px solid #dee2e6;
}

.search-filter-wrapper {
    flex-direction: column;
    align-items: stretch !important;
}

.search-box {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 1rem;
}

.filter-button {
    width: 100%;
    margin-right: 0 !important;
}

.btn-filter {
    width: 100%;
}

.filter-content {
    flex-direction: column;
}

.select-wrapper {
    width: 100%;
    margin-bottom: 1rem;
}

.button-wrapper {
    width: 100%;
}

.filter-buttons {
    display: flex;
    gap: 0.5rem;
}

.filter-buttons .btn {
    flex: 1;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.pagination-wrapper {
    flex-direction: column;
    gap: 1rem;
}

.pagination-info {
    text-align: center;
    width: 100%;
}

.pagination-select {
    width: 100%;
    margin-left: 0 !important;
}

.pagination-select .d-flex {
    justify-content: center;
}

@media screen and (min-width: 992px) {
    .w-search {
        width: 400px;
    }

    .search-filter-wrapper {
        flex-direction: row;
        align-items: center !important;
    }

    .search-box {
        width: auto;
        margin-right: 1rem !important;
        margin-bottom: 0;
    }

    .filter-button {
        width: auto;
        margin-right: 1rem !important;
    }

    .btn-filter {
        width: auto;
    }

    .filter-content {
        flex-direction: row;
    }

    .select-wrapper {
        width: auto;
        margin-bottom: 0;
    }

    .button-wrapper {
        width: auto;
    }

    .filter-buttons {
        display: flex;
        gap: 0.5rem;
    }

    .filter-buttons .btn {
        flex: none;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .pagination-wrapper {
        flex-direction: row;
        justify-content: space-between;
        gap: 0;
    }

    .pagination-info {
        text-align: left;
        width: auto;
    }

    .pagination-select {
        width: auto;
        margin-left: 1rem !important;
    }

    .pagination-select .d-flex {
        justify-content: flex-start;
    }

    .sticky-footer {
        max-width: 500px;
        padding: 1rem;
    }
}

.btn-outline-yellow {
    background-color: transparent !important;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.modal-vue {
    position: fixed;
    z-index: 9999;
}
.transition-transform {
    transition: transform 0.3s ease;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
hr {
    color: #dee2e6 !important;
    margin: 1.5rem 0;
}
.input-sidebar {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1040;
}
.sidebar {
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 500px;
    height: 100vh;
    background-color: #f9fafb;
    z-index: 1050;
    transition: right 0.3s ease-in-out;
    box-shadow: -8px 0 10px -6px rgba(0, 0, 0, 0.1);
    margin-top: 0;
}

.sidebar-open {
    right: 0;
}
.btn-green:disabled {
    background: #e0e6eb !important;
    border: none !important;
    color: #849bae !important;
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
.service {
    background-color: #cce7ff !important;
    border: 1px solid #99d0ff !important;
    color: #005299 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    background-color: transparent !important;
    color: #40bf76 !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    border: 1px solid #40bf76 !important;
    background-color: transparent !important;
    color: #40bf76 !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
</style>

<template>
    <div class="padding-container">
        <div class="content-load" v-if="detailLoading"></div>
        <div id="loader" v-if="detailLoading"></div>
        <div class="fw-bold">
            <div @click="$router.go(-1)" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="fw-bold mb-4">
                Tipe Customer <span class="text-danger">*</span>
            </h5>
            <div class="row">
                <div
                    class="col-lg-6 cursor-pointer mb-3"
                    @click="customerType = 'individu'"
                >
                    <div
                        class="box h-100 align-items-center d-flex"
                        :class="{ selected: customerType === 'individu' }"
                    >
                        <label class="container-radio">
                            <div class="d-flex">
                                <img
                                    src="../assets/individu-active.svg"
                                    alt="individu"
                                    class="me-3"
                                    v-if="customerType === 'individu'"
                                />
                                <img
                                    v-else
                                    src="../assets/individu.svg"
                                    alt="individu"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="fw-bold mb-0">Individu</p>
                                    <p class="text-gray mb-0">
                                        Pilihan untuk keperluan pengiriman
                                        pribadi
                                    </p>
                                </div>
                            </div>
                            <input
                                type="radio"
                                name="radio"
                                v-model="customerType"
                                value="individu"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div
                    class="col-lg-6 cursor-pointer mb-3"
                    @click="customerType = 'corporate'"
                >
                    <div
                        class="box h-100align-items-center d-flex"
                        :class="{ selected: customerType === 'corporate' }"
                    >
                        <label class="container-radio">
                            <div class="d-flex">
                                <img
                                    src="../assets/va-active.svg"
                                    alt="va"
                                    class="me-3"
                                    v-if="customerType === 'corporate'"
                                />
                                <img
                                    v-else
                                    src="../assets/va.svg"
                                    alt="va"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="fw-bold mb-0">Corporate</p>
                                    <p class="text-gray mb-0">
                                        Pelanggan bisnis dengan perhitungan
                                        pajak yang disesuaikan.
                                    </p>
                                </div>
                            </div>
                            <input
                                type="radio"
                                name="radio"
                                v-model="customerType"
                                value="corporate"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <div class="d-flex align-items-center mb-3">
                <h5 class="mb-0 fw-bold me-4" v-if="dealCode">
                    Deal ID - {{ dealCode }}
                </h5>
                <h5 class="mb-0 fw-bold me-4" v-else>Data Klaim Pesanan</h5>
                <div
                    v-if="orderType === 'walk_in'"
                    class="mb-0 pb-0 capitalize d-flex justify-content-center rounded-pill px-3 badge-walk-in"
                >
                    Walk In
                </div>
                <div
                    v-else
                    class="mb-0 pb-0 capitalize d-flex justify-content-center rounded-pill px-3 badge-regular"
                >
                    Regular
                </div>
            </div>
            <h5 class="mb-0 fw-bold">Data Pengirim</h5>
            <div class="row mt-4">
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nama Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama pengirim"
                        v-model="senderName"
                    />
                </div>
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nomor Handphone Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nomor pengirim"
                            @input="validatePhoneSender()"
                            v-model="senderPhone"
                        />
                    </div>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kabupaten Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="senderRegency"
                        :options="senderRegencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten asal"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-12 mt-2">
                    <label class="fw-bold mb-1">
                        Alamat Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Masukkan alamat pengirim"
                        v-model="senderAddress"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="mb-0 fw-bold">Data Penerima</h5>
            <div class="row mt-4">
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nama Penerima
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama penerima"
                        v-model="receiverName"
                    />
                </div>
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nomor Handphone Penerima
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nomor penerima"
                            @input="validatePhoneReceiver()"
                            v-model="receiverPhone"
                        />
                    </div>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Provinsi
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="receiverProvince"
                        :options="receiverProvinces"
                        label="name"
                        track-by="id"
                        placeholder="Pilih provinsi"
                        class="multi-form-custom"
                        :show-labels="false"
                        @input="changeReceiverProvince()"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/kabupaten
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="receiverRegency"
                        :options="receiverRegencies"
                        label="name"
                        track-by="id"
                        placeholder="Pilih kota/kabupaten"
                        class="multi-form-custom"
                        :show-labels="false"
                        :disabled="!receiverProvince"
                        @input="changeReceiverRegency()"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kecamatan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="receiverDistrict"
                        :options="receiverDistricts"
                        label="name"
                        track-by="id"
                        placeholder="Pilih kecamatan"
                        class="multi-form-custom"
                        :show-labels="false"
                        :disabled="!receiverRegency"
                        @input="changeReceiverDistrict()"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-2">
                    <label class="fw-bold mb-1">
                        Kelurahan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="receiverSubDistrict"
                        :options="receiverSubDistricts"
                        label="name"
                        track-by="id"
                        placeholder="Pilih Kelurahan"
                        class="multi-form-custom"
                        :show-labels="false"
                        :disabled="!receiverDistrict"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 mt-2">
                    <label class="fw-bold mb-1">Kode Pos</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan kode pos"
                        v-model="receiverZipCode"
                        @input="validateZipCode()"
                    />
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="fw-bold mb-1">
                        Alamat Penerima
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Masukkan alamat lengkap penerima"
                        v-model="receiverAddress"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="fw-bold mb-3">Pilih Jenis Layanan</h5>
            <label class="fw-bold mb-1">
                Jenis Layanan
                <span class="text-danger fw-bold">*</span>
            </label>
            <div class="form-box text-capitalize">
                {{ serviceType.replace(/_/g, " ") }}
            </div>
        </div>
        <div
            class="box-manifest mt-3"
            v-if="serviceType === 'pengiriman_barang'"
        >
            <h5 class="mb-0 fw-bold">
                Hasil Ukur - Timbang Barang <span class="text-danger">*</span>
            </h5>
            <div class="table-responsive-custom">
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">Jumlah Koli</th>
                            <th class="text-center" scope="col">Deskripsi</th>
                            <th class="text-center" scope="col">
                                Dimensi Barang (Cm)
                            </th>
                            <th class="text-center" scope="col">Berat (Kg)</th>
                            <th class="text-center" scope="col">
                                Berat Volume (Kg)
                            </th>
                            <th class="text-center" scope="col">
                                Kubikasi (m3)
                            </th>
                            <th class="text-center" scope="col">
                                Perlindungan
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                                {{ item.qty }}
                            </td>
                            <td>{{ item.description }}</td>
                            <td class="text-center">
                                {{ item.dimension.length }} Cm X
                                {{ item.dimension.width }} Cm X
                                {{ item.dimension.height }} Cm
                            </td>
                            <td
                                class="text-center"
                                v-bind:class="{
                                    'bc-green':
                                        item.weight >
                                        Number(item.weight_volume),
                                }"
                            >
                                {{ item.weight }} Kg
                            </td>
                            <td
                                class="text-center"
                                v-bind:class="{
                                    'bc-green':
                                        Number(item.weight_volume) >
                                        item.weight,
                                }"
                            >
                                {{ item.weight_volume }} Kg
                            </td>
                            <td class="text-center">{{ item.cubic }} m3</td>
                            <td class="capitalize">
                                <div
                                    class="text-center"
                                    v-if="!item.protection"
                                >
                                    -
                                </div>
                                <div
                                    v-for="(handling, index) in item.protection"
                                    :key="index"
                                >
                                    <span class="tag gray-new radius pb-0">
                                        {{
                                            handling.packing
                                                ? handling.packing.replace(
                                                      /_/g,
                                                      " "
                                                  )
                                                : "-"
                                        }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody class="body">
                        <tr>
                            <td class="p-footer" colspan="4">
                                <div class="pull-right fw-bold">Total</div>
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ totalWeight }} Kg
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ totalVolume }} Kg
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ totalCubic }} m3
                            </td>
                            <td class="p-footer"></td>
                        </tr>
                        <tr>
                            <td class="p-footer" colspan="4">
                                <div class="pull-right fw-bold">
                                    Charge Weight (Kg)
                                </div>
                            </td>
                            <td
                                class="text-center bc-green p-footer"
                                colspan="4"
                            >
                                {{ chargeWeight }} Kg
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <label class="fw-bold mb-1 mt-3">Catatan</label>
            <div class="form-box">{{ note ? note : "-" }}</div>
        </div>
        <div
            class="box-manifest mt-3"
            v-if="serviceType === 'pengiriman_motor'"
        >
            <h5 class="mb-0 fw-bold">
                Detail Pengiriman Motor <span class="text-danger">*</span>
            </h5>
            <div class="row">
                <div class="mt-3 col-lg-6">
                    <label class="mb-2"
                        >Tipe Motor <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="bikeType"
                        disabled
                    />
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="mb-2"
                        >Kapasitas Motor
                        <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="bikeCapacity"
                        disabled
                    />
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="mb-2">Perlindungan Barang </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="bikePacking"
                        disabled
                    />
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="mb-2">Deskripsi</label>
                    <textarea
                        class="form-control"
                        v-model="bikeDescription"
                        disabled
                    ></textarea>
                </div>
            </div>
        </div>
        <div
            class="box-manifest mt-3"
            v-if="serviceType === 'pengiriman_mobil'"
        >
            <h5 class="mb-0 fw-bold">
                Detail Pengiriman Mobil <span class="text-danger">*</span>
            </h5>
            <div class="row">
                <div class="mt-3 col-lg-6">
                    <label class="mb-2"
                        >Tipe Mobil <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="carType"
                        disabled
                    />
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="mb-2">Deskripsi</label>
                    <textarea
                        class="form-control"
                        v-model="carDescription"
                        disabled
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <h5 class="fw-bold mb-4">
                Metode Pembayaran <span class="text-danger">*</span>
            </h5>
            <div class="row">
                <div
                    class="col-lg-6 cursor-pointer"
                    @click="selectedPayment = 'cash'"
                >
                    <div
                        class="box h-100 align-items-center d-flex"
                        :class="{ selected: selectedPayment === 'cash' }"
                    >
                        <label class="container-radio">
                            <div class="d-flex">
                                <img
                                    src="../assets/money-active.svg"
                                    alt="money"
                                    class="me-3"
                                    v-if="selectedPayment === 'cash'"
                                />
                                <img
                                    v-else
                                    src="../assets/money.svg"
                                    alt="money"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="fw-bold mb-0">Tunai</p>
                                    <p class="text-gray mb-0">
                                        Pembayaran Tunai
                                    </p>
                                </div>
                            </div>
                            <input
                                type="radio"
                                name="radio"
                                value="cash"
                                v-model="selectedPayment"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div
                    v-if="paymentVa === 'enable'"
                    class="col-lg-6 cursor-pointer"
                    @click="selectedPayment = 'va'"
                >
                    <div
                        class="box h-100align-items-center d-flex"
                        :class="{ selected: selectedPayment === 'va' }"
                    >
                        <label class="container-radio">
                            <div class="d-flex">
                                <img
                                    src="../assets/va-active.svg"
                                    alt="va"
                                    class="me-3"
                                    v-if="selectedPayment === 'va'"
                                />
                                <img
                                    v-else
                                    src="../assets/va.svg"
                                    alt="va"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="fw-bold mb-0">Virtual Account</p>
                                    <p class="text-gray mb-0">
                                        Pembayaran VA dari banyak pilihan bank
                                    </p>
                                </div>
                            </div>
                            <input
                                type="radio"
                                name="radio"
                                value="va"
                                v-model="selectedPayment"
                            />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div
                    class="col-lg-12 position-relative mt-2"
                    v-if="selectedPayment === 'va'"
                >
                    <label class="fw-bold mb-1 mt-3">
                        Bank
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div
                        class="form-control cursor-pointer"
                        :class="{ 'mb-4': !isDropdownOpen }"
                        @click="isDropdownOpen = !isDropdownOpen"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center w-100"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    v-if="selectedBank"
                                    :src="selectedBank.asset"
                                    :alt="selectedBank.asset"
                                    width="50"
                                    class="me-2"
                                />
                                <p
                                    class="mb-0"
                                    :class="{
                                        'text-gray': !selectedBank,
                                    }"
                                >
                                    {{
                                        selectedBank
                                            ? selectedBank.label
                                            : "Pilih bank pembayaran"
                                    }}
                                </p>
                            </div>
                            <img
                                src="../assets/arrow-down-2.svg"
                                alt="arrow down 2"
                                :class="{ 'rotate-arrow': isDropdownOpen }"
                            />
                        </div>
                    </div>
                    <div
                        v-if="isDropdownOpen"
                        class="form-control my-2 position-absolute z-3"
                    >
                        <div
                            v-for="(channel, index) in channels"
                            :key="index"
                            class="d-flex justify-content-center flex-column py-2 cursor-pointer"
                            :class="{
                                'border-bottom': index !== channels.length - 1,
                            }"
                            @click="selectBank(channel)"
                        >
                            <div class="d-flex align-items-center">
                                <img
                                    :src="channel.asset"
                                    :alt="channel.asset"
                                    width="50"
                                    class="me-2"
                                />
                                {{ channel.label }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4" v-if="suggestion">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
                <div class="box selected">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                src="../assets/information.svg"
                                alt="information"
                                class="me-2"
                            />
                            <h6 class="mb-0">Informasi Harga Pengiriman</h6>
                        </div>
                        <h5 class="mb-0 text-suggestion">
                            {{ currency(suggestion) }}/Kg
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
                <div class="box-manifest">
                    <h5 class="mb-0 fw-bold">Total Biaya Pengiriman</h5>
                    <div class="d-flex align-items-center mt-3">
                        <div>Biaya Pengiriman</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditService = !isEditService"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditService == false,
                                    'text-primary': this.isEditService == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditService">
                                {{ currency(servicePrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="servicePrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('service')"
                                    :style="{
                                        width: moneyInputWidths.service + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <div>Biaya Packing</div>
                        <div
                            class="ms-auto fw-bold d-flex align-items-center"
                            v-if="
                                serviceType === 'pengiriman_motor' &&
                                bikePacking === ''
                            "
                        >
                            {{ currency(packingPrice) }}
                        </div>
                        <div
                            class="ms-auto fw-bold d-flex align-items-center"
                            v-if="
                                serviceType === 'pengiriman_barang' ||
                                serviceType === 'pengiriman_mobil' ||
                                serviceType === 'sewa_container_20ft' ||
                                serviceType === 'sewa_container_40ft'
                            "
                        >
                            {{ currency(packingPrice) }}
                        </div>
                        <div
                            class="ms-auto fw-bold d-flex align-items-center"
                            v-if="
                                serviceType === 'pengiriman_motor' &&
                                bikePacking !== ''
                            "
                        >
                            <a
                                href="javascript:void(0)"
                                @click="isEditPacking = !isEditPacking"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditPacking == false,
                                    'text-primary': this.isEditPacking == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditPacking">
                                {{ currency(packingPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="packingPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('packing')"
                                    :style="{
                                        width: moneyInputWidths.packing + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex align-items-center mt-2">
                        <div>Biaya Jasa Tambahan</div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Alat Berat</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="
                                    isEditHeavyEquipment = !isEditHeavyEquipment
                                "
                                class="me-1"
                                v-bind:class="{
                                    'text-gray':
                                        this.isEditHeavyEquipment == false,
                                    'text-primary':
                                        this.isEditHeavyEquipment == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditHeavyEquipment">
                                {{ currency(heavyEquipmentPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="heavyEquipmentPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('heavyEquipment')"
                                    :style="{
                                        width:
                                            moneyInputWidths.heavyEquipment +
                                            'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Penerusan</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditForwarding = !isEditForwarding"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditForwarding == false,
                                    'text-primary':
                                        this.isEditForwarding == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditForwarding">
                                {{ currency(forwardingPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="forwardingPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('forwarding')"
                                    :style="{
                                        width:
                                            moneyInputWidths.forwarding + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Penjemputan</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditPickUp = !isEditPickUp"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditPickUp == false,
                                    'text-primary': this.isEditPickUp == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditPickUp">
                                {{ currency(pickUpPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="pickUpPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('pickUp')"
                                    :style="{
                                        width: moneyInputWidths.pickUp + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Inap Barang</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditOverNight = !isEditOverNight"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditOverNight == false,
                                    'text-primary':
                                        this.isEditOverNight == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditOverNight">
                                {{ currency(overNightPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="overNightPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('overNight')"
                                    :style="{
                                        width:
                                            moneyInputWidths.overNight + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Racking</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditRacking = !isEditRacking"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditRacking == false,
                                    'text-primary': this.isEditRacking == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditRacking">
                                {{ currency(rackingPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="rackingPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('racking')"
                                    :style="{
                                        width: moneyInputWidths.racking + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2 ms-3">
                        <div>Biaya Buruh</div>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            <a
                                href="javascript:void(0)"
                                @click="isEditLabor = !isEditLabor"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditLabor == false,
                                    'text-primary': this.isEditLabor == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            <template v-if="!isEditLabor">
                                {{ currency(laborPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="laborPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    class="form-control form-edit"
                                    @input="updateWidth('labor')"
                                    :style="{
                                        width: moneyInputWidths.labor + 'px',
                                    }"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex align-items-center mt-2">
                        <div class="text-yellow">Diskon</div>
                        <div
                            class="ms-auto fw-bold text-yellow d-flex align-items-center"
                        >
                            <a
                                href="javascript:void(0)"
                                @click="isEditDiscount = !isEditDiscount"
                                class="me-1"
                                v-bind:class="{
                                    'text-gray': this.isEditDiscount == false,
                                    'text-primary': this.isEditDiscount == true,
                                }"
                            >
                                <i class="fa fa-pencil size-16"></i>
                            </a>
                            -<template v-if="!isEditDiscount">
                                {{ currency(discountPrice) }}
                            </template>
                            <template v-else>
                                <money
                                    v-model="discountPrice"
                                    v-bind="formatNumber"
                                    v-autofocus
                                    @input="updateWidth('discount')"
                                    :style="{
                                        width: moneyInputWidths.discount + 'px',
                                    }"
                                    class="form-control form-edit yellow"
                                >
                                </money>
                            </template>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex align-items-center mt-2">
                        <h5 class="mb-0 fw-bold">Sub Total</h5>
                        <div class="ms-auto fw-bold d-flex align-items-center">
                            {{ currency(calculateSubTotal) }}
                        </div>
                    </div>
                    <div class="box-manifest mt-3">
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="custom-checkbox"
                                v-model="withTax"
                            />
                            <div class="d-flex flex-column ms-3">
                                <p class="fw-bold mb-0">
                                    Tambahkan Komponen Pajak
                                </p>
                                <p class="mb-0">
                                    Otomatis menambahkan nilai pajak ke total
                                    pesanan
                                </p>
                            </div>
                        </div>
                        <hr v-if="withTax" />
                        <div
                            class="d-flex align-items-center mt-2"
                            v-if="withTax"
                        >
                            <div class="text-yellow">PPH 2%</div>
                            <div
                                class="ms-auto fw-bold text-yellow d-flex align-items-center"
                            >
                                - {{ currency(pph) }}
                            </div>
                        </div>
                        <div
                            class="d-flex align-items-center mt-2"
                            v-if="withTax"
                        >
                            <div class="mb-0 fw-bold">PPN 1,2%</div>
                            <div
                                class="ms-auto fw-bold d-flex align-items-center"
                            >
                                {{ currency(ppn) }}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-3">
                        <h5 class="text-green fw-bold">Total Pembayaran</h5>
                        <div class="ms-auto fw-bold text-green">
                            {{ currency(calculateTotalPayment) }}
                        </div>
                    </div>
                    <div class="mt-3">
                        <button
                            class="btn btn-primary w-100"
                            @click="modalSuccessConfirmation = true"
                        >
                            Klaim Pesanan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessConfirmation">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Akan Diklaim</h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin mengklaim pesanan ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isSave"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isSave"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Berhasil Diklaim</h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/order/process"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessVa">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">Pesanan Berhasil dibuat</h5>
                    <div class="text-gray-dashboard">
                        Segera lakukan pembayaran melalui virtual account
                    </div>
                </div>
                <div class="mt-4">
                    <router-link
                        :to="'/order/cashier/va/' + receiptCode"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import { Money } from "v-money";

export default {
    components: {
        Multiselect,
        Money,
    },
    data() {
        return {
            detailLoading: false,
            customerType: "individu",
            dealCode: "",
            orderType: "",
            senderName: "",
            senderPhone: "",
            senderRegency: null,
            senderRegencies: [],
            senderAddress: "",
            receiverName: "",
            receiverPhone: "",
            receiverProvince: null,
            receiverProvinces: [],
            receiverRegency: null,
            receiverRegencies: [],
            receiverDistrict: null,
            receiverDistricts: [],
            receiverSubDistrict: null,
            receiverSubDistricts: [],
            receiverZipCode: "",
            receiverAddress: "",
            serviceType: "",
            items: null,
            note: "",
            suggestion: null,
            bikeType: "",
            bikeCapacity: "",
            bikePacking: "",
            bikeDescription: "",
            carType: "",
            carDescription: "",
            selectedPayment:
                process.env.VUE_APP_PAYMENT_VA === "enable" ? null : "cash",
            selectedBank: null,
            isDropdownOpen: false,
            channels: [],
            moneyInputWidths: {
                service: 30,
                packing: 30,
                heavyEquipment: 30,
                forwarding: 30,
                pickUp: 30,
                overNight: 30,
                racking: 30,
                labor: 30,
                discount: 30,
            },
            formatNumber: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp",
                suffix: "",
                precision: 0,
                masked: false,
                maxLength: 13,
                minimumValue: 0,
            },
            servicePrice: 0,
            packingPrice: 0,
            heavyEquipmentPrice: 0,
            forwardingPrice: 0,
            pickUpPrice: 0,
            overNightPrice: 0,
            rackingPrice: 0,
            laborPrice: 0,
            discountPrice: 0,
            totalPrice: 0,
            isEditService: false,
            isEditPacking: false,
            isEditHeavyEquipment: false,
            isEditForwarding: false,
            isEditPickUp: false,
            isEditOverNight: false,
            isEditRacking: false,
            isEditLabor: false,
            isEditDiscount: false,
            isSave: false,
            modalSuccessConfirmation: false,
            modalSuccess: false,
            modalSuccessVa: false,
            receiptCode: null,
            paymentVa: process.env.VUE_APP_PAYMENT_VA,
            withTax: false,
        };
    },
    watch: {
        senderRegency() {
            this.checkAndCallGetSuggestion();
        },
        receiverRegency() {
            this.checkAndCallGetSuggestion();
        },
        serviceType() {
            this.checkAndCallGetSuggestion();
        },
    },
    async created() {
        this.detailLoading = true;
        await this.getSenderRegencies();
        await this.getReceiverProvinces();
        await this.getDetails();
        if (this.receiverProvince) {
            await this.getReceiverRegencies();
            await this.getReceiverDistricts();
        }
        await this.getChannels();
    },
    computed: {
        totalWeight() {
            return this.items.reduce(
                (total, item) => total + item.qty * item.weight,
                0
            );
        },
        totalVolume() {
            return Math.round(
                this.items.reduce(
                    (total, item) => total + item.qty * item.weight_volume,
                    0
                )
            );
        },
        totalCubic() {
            return this.items.reduce(
                (total, item) => total + item.qty * item.cubic,
                0
            );
        },
        chargeWeight() {
            return Math.max(this.totalWeight, this.totalVolume);
        },
        calculateAdditionalTotal() {
            const heavyEquipmentPrice = this.heavyEquipmentPrice;
            const forwardingPrice = this.forwardingPrice;
            const pickUpPrice = this.pickUpPrice;
            const overNightPrice = this.overNightPrice;
            const rackingPrice = this.rackingPrice;
            const laborPrice = this.laborPrice;

            return (
                heavyEquipmentPrice +
                forwardingPrice +
                pickUpPrice +
                overNightPrice +
                rackingPrice +
                laborPrice
            );
        },
        calculateSubTotal() {
            const servicePrice = this.servicePrice;
            const packingPrice = this.packingPrice;
            const discountPrice = this.discountPrice;

            return (
                servicePrice +
                packingPrice +
                this.calculateAdditionalTotal -
                discountPrice
            );
        },
        pph() {
            return (2 * this.calculateSubTotal) / 100;
        },
        ppn() {
            return (1.2 * this.calculateSubTotal) / 100;
        },
        calculateTotalPayment() {
            if (this.withTax) {
                return this.calculateSubTotal - this.pph + this.ppn;
            } else {
                return this.calculateSubTotal;
            }
        },
    },
    directives: {
        autofocus: {
            inserted: (el) => {
                el.focus();
            },
        },
    },
    methods: {
        storeData() {
            this.isSave = true;
            const param = this.$route.query;

            var data = {
                claim_id: param.claim_id,
                customer_type: this.customerType,
                sender_name: this.senderName,
                sender_phone: this.senderPhone,
                origin_regency_id: this.senderRegency?.id,
                sender_address: this.senderAddress,
                receiver_name: this.receiverName,
                receiver_phone: this.receiverPhone,
                destination_province_id: this.receiverProvince?.id,
                destination_regency_id: this.receiverRegency?.id,
                destination_district_id: this.receiverDistrict?.id,
                destination_subdistrict_id: this.receiverSubDistrict?.id,
                receiver_zipcode: this.receiverZipCode,
                receiver_address: this.receiverAddress,
                service_type: this.serviceType,
                payment_method: this.selectedPayment,
                service_price: this.servicePrice,
                packing_price: this.packingPrice,
                additional_price: this.calculateAdditionalTotal,
                discount_price: this.discountPrice,
                additionals: [
                    {
                        packing: "Biaya Alat Berat",
                        price: this.heavyEquipmentPrice,
                    },
                    {
                        packing: "Biaya Penerusan",
                        price: this.forwardingPrice,
                    },
                    {
                        packing: "Biaya Penjemputan",
                        price: this.pickUpPrice,
                    },
                    {
                        packing: "Biaya Inap Barang",
                        price: this.overNightPrice,
                    },
                    {
                        packing: "Biaya Racking",
                        price: this.rackingPrice,
                    },
                    {
                        packing: "Biaya Buruh",
                        price: this.laborPrice,
                    },
                ],
                pph: this.withTax ? this.pph : 0,
                ppn: this.withTax ? this.ppn : 0,
            };
            if (this.selectedPayment === "va") {
                data.va = this.selectedBank?.va;
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/order/claims-v2`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.isSave = false;
                    if (this.selectedPayment === "cash") {
                        this.modalSuccess = true;
                    } else {
                        this.receiptCode = res.data.data.receipt_code;
                        this.modalSuccessVa = true;
                    }
                    this.modalSuccessConfirmation = false;
                })
                .catch((err) => {
                    this.isSave = false;
                    this.modalSuccessConfirmation = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        selectBank(channel) {
            this.selectedBank = channel;
            this.isDropdownOpen = false;
        },
        getChannels() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/payment/channel`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.channels = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        updateWidth(type) {
            const values = {
                service: this.servicePrice,
                packing: this.packingPrice,
                heavyEquipment: this.heavyEquipmentPrice,
                forwarding: this.forwardingPrice,
                pickUp: this.pickUpPrice,
                overNight: this.overNightPrice,
                racking: this.rackingPrice,
                labor: this.laborPrice,
                discount: this.discountPrice,
            };

            const valueLength = String(values[type]).length;
            this.moneyInputWidths[type] = 30 + (valueLength - 1) * 10;
        },
        checkAndCallGetSuggestion() {
            if (
                this.senderRegency?.id &&
                this.receiverRegency?.id &&
                this.serviceType
            ) {
                this.getSuggestion();
            }
        },
        getSuggestion() {
            var payload = {
                origin_regency_id: this.senderRegency?.id,
                destination_regency_id: this.receiverRegency?.id,
                service_type: this.serviceType,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order/get_suggestion`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.suggestion = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        async getDetails() {
            var payload = {
                claim_id: this.$route.query.claim_id,
            };
            return Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/order/claims/detail`,
                {
                    params: payload,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    const data = res?.data?.data;
                    this.dealCode = data?.deal_code;
                    this.orderType = data?.order_type;
                    this.senderName = data?.sender_data?.name;
                    this.senderPhone = data?.sender?.phone;
                    this.senderRegency = data?.sender_data?.regency_id
                        ? this.senderRegencies.find(
                              (r) => r.id === data?.sender_data?.regency_id
                          )
                        : null;
                    this.senderAddress = data?.sender_data?.address;
                    this.receiverName = data?.receiver_data?.name;
                    this.receiverPhone = data?.receiver_data?.phone;
                    this.receiverProvince = data?.receiver_data?.province_id
                        ? this.receiverProvinces.find(
                              (r) => r.id === data?.receiver_data?.province_id
                          )
                        : null;
                    this.receiverRegency = data?.receiver_data?.regency_id
                        ? this.senderRegencies.find(
                              (r) => r.id === data?.receiver_data?.regency_id
                          )
                        : null;
                    this.serviceType = data?.service_type;
                    this.items = data?.items;
                    this.note = data?.note;
                    this.packingPrice = data?.packing_price;
                    this.bikeType = data?.vehicle;
                    this.bikeCapacity = this.getBikeCCName(data.cc);
                    this.bikePacking = data?.protections
                        .map((item) =>
                            item.protection
                                .replace(/_/g, " ")
                                .replace(/\b\w/g, (char) => char.toUpperCase())
                        )
                        .join(", ");
                    this.bikeDescription = data?.note;
                    this.carType = data?.vehicle;
                    this.carDescription = data?.note;
                    this.detailLoading = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.detailLoading = false;
                });
        },
        getBikeCCName(cc) {
            const ccMapping = {
                lower: "100 CC - 149 CC",
                middle: "150 CC - 249 CC",
                high: "Diatas 250 CC",
            };
            return ccMapping[cc] || "";
        },
        async getSenderRegencies() {
            return Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.senderRegencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        async getReceiverProvinces() {
            return Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/province`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.receiverProvinces = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        async getReceiverRegencies() {
            return Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency?province_id=${this.receiverProvince?.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.receiverRegencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        async getReceiverDistricts() {
            return Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/district?regency_id=${this.receiverRegency?.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.receiverDistricts = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        async getReceiverSubDistrict() {
            return Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/subdistrict?district_id=${this.receiverDistrict?.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.receiverSubDistricts = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        validatePhoneSender() {
            if (!this.senderPhone.startsWith("8")) {
                this.senderPhone =
                    "8" + this.senderPhone.replace(/[^0-9]/g, "");
            } else {
                this.senderPhone = this.senderPhone.replace(/[^0-9]/g, "");
            }
        },
        changeReceiverProvince() {
            this.receiverRegency = null;
            this.receiverDistrict = null;
            this.receiverSubDistrict = null;
            if (this.receiverProvince) {
                this.getReceiverRegencies();
            }
        },
        changeReceiverRegency() {
            this.receiverDistrict = null;
            this.receiverSubDistrict = null;
            if (this.receiverRegency) {
                this.getReceiverDistricts();
            }
        },
        changeReceiverDistrict() {
            this.receiverSubDistrict = null;
            if (this.receiverDistrict) {
                this.getReceiverSubDistrict();
            }
        },
        validatePhoneReceiver() {
            if (!this.receiverPhone.startsWith("8")) {
                this.receiverPhone =
                    "8" + this.receiverPhone.replace(/[^0-9]/g, "");
            } else {
                this.receiverPhone = this.receiverPhone.replace(/[^0-9]/g, "");
            }
        },
        validateZipCode() {
            this.receiverZipCode = this.receiverZipCode.replace(/[^0-9]/g, "");
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}
.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
hr {
    color: #c1cdd7;
}
::v-deep .v-money.form-control {
    background-color: #f9fafb;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody.body {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.badge-walk-in {
    background-color: #e0e6eb !important;
    border: 1px solid #c2cdd6 !important;
    color: #3e4e5c !important;
}
.badge-regular {
    background-color: #cce7ff !important;
    border: 1px solid #99d0ff !important;
    color: #005299 !important;
}
.container-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 0%;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #a3b4c2;
    transform: translate(0%, -50%);
}

.container-radio input:checked ~ .checkmark {
    background-color: transparent;
    border: 2px solid #329a5f;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-radio input:checked ~ .checkmark:after {
    display: block;
}

.container-radio .checkmark:after {
    top: 2.6px;
    left: 2.6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #329a5f;
}

.box.selected {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.box.selected p {
    color: #329a5f !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
::v-deep .multiselect--disabled .multiselect__current,
::v-deep .multiselect--disabled .multiselect__select,
::v-deep .multiselect--disabled .multiselect__tags,
::v-deep .multiselect--disabled .multiselect__placeholder {
    background: #f3f5f7 !important;
    color: #495057 !important;
}
th {
    border-color: #c1cdd7;
    padding: 10px !important;
}
tr {
    border-color: #c1cdd7;
    padding: 10px !important;
}
td {
    padding: 10px !important;
    vertical-align: middle;
}
.table-light {
    background-color: #f7f7f7 !important;
    --bs-table-border-color: #f7f7f7 !important;
    --bs-table-bg: #f7f7f7 !important;
}
.text-suggestion {
    color: #329a5f !important;
}
td.bc-green {
    background-color: #d9f2e4;
    color: #329a5f;
    font-weight: 600 !important;
}
.table-responsive-custom table tbody tr td {
    font-weight: 300;
}
td.p-footer {
    padding: 5px !important;
}
.radius-select {
    border-radius: 7px;
    background: #f3f5f7;
    color: #000;
    font-weight: 500;
    padding: 7px 10px;
    border: 1px solid #d1d0d4;
}
.form-edit {
    border: unset !important;
    text-align: right;
    padding: 0px;
    font-weight: 600 !important;
    color: #000 !important;
    border-radius: unset !important;
}
.form-edit.yellow {
    color: #ff8600 !important;
}
.text-gray {
    color: #a3b4c2 !important;
}
.rotate-arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}
textarea {
    resize: none;
}
</style>

<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="row flex-column">
                    <div class="col-lg-12 my-3">
                        <div class="box-manifest">
                            <h5 class="mb-0 fw-bold">Informasi Vendor</h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr>
                                    <td class="ps-0 fw-medium">Nama Vendor</td>
                                    <td>:</td>
                                    <td class="fw-bold">
                                        <div
                                            class="d-flex align-items-center cursor-pointer"
                                            @click="
                                                openSidebar(detail?.vendor_id)
                                            "
                                        >
                                            <p class="text-link mb-0 me-2">
                                                {{ detail?.vendor_name }}
                                            </p>
                                            <img
                                                src="../assets/arrow-blue.svg"
                                                alt="arrow blue"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12 my-3">
                        <div class="box-manifest">
                            <h5 class="mb-0 fw-bold">Informasi Manifest</h5>
                            <div
                                class="my-3"
                                v-for="(
                                    information, index
                                ) in detail?.manifest_information"
                                :key="index"
                            >
                                <div
                                    class="d-flex justify-content-between header-sidebar align-items-center px-3 py-2"
                                    :class="{
                                        'bb-r': !expandedSections.includes(
                                            index
                                        ),
                                    }"
                                    @click="toggleSection(index)"
                                >
                                    <p class="mb-0">{{ information.code }}</p>
                                    <img
                                        src="../assets/chevron-down.svg"
                                        alt="chevron down"
                                        :class="{
                                            'rotate-180':
                                                expandedSections.includes(
                                                    index
                                                ),
                                        }"
                                    />
                                </div>
                                <div
                                    class="body-sidebar px-3 py-2"
                                    v-show="expandedSections.includes(index)"
                                >
                                    <table
                                        class="w-100 ps-0 fw-bold mt-3"
                                        cellpadding="10"
                                    >
                                        <tr class="border-bottom">
                                            <td class="ps-0 fw-medium">Rute</td>
                                            <td>:</td>
                                            <td class="fw-bold">
                                                {{ information.route }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="ps-0 fw-medium">
                                                Order ID
                                            </td>
                                            <td>:</td>
                                            <td class="fw-bold">
                                                <div class="receipt-code">
                                                    <p
                                                        v-for="(
                                                            receipt, index
                                                        ) in information?.receipt_code"
                                                        :key="index"
                                                    >
                                                        {{ receipt }}
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row-flex-column">
                    <div class="col-lg-12 my-3">
                        <div class="box-manifest">
                            <h5 class="mb-0 fw-bold">Informasi Pembayaran</h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr class="border-bottom">
                                    <td class="ps-0 fw-medium">
                                        Total Pembayaran
                                    </td>
                                    <td>:</td>
                                    <td class="fw-bold">
                                        {{
                                            currency(
                                                detail?.payment_information
                                                    ?.total_payment
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 fw-medium">Nama Bank</td>
                                    <td>:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail?.payment_information
                                                ?.bank_name
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 fw-medium">
                                        Nomor Rekening
                                    </td>
                                    <td>:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail?.payment_information
                                                ?.account_number
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 fw-medium">
                                        Nama Pemilik Rekening
                                    </td>
                                    <td>:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail?.payment_information
                                                ?.account_name
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 fw-medium">
                                        Status Pembayaran
                                    </td>
                                    <td>:</td>
                                    <td class="fw-bold">
                                        <div
                                            class="paid-badge rounded-pill d-inline px-3"
                                            v-if="
                                                detail?.payment_information
                                                    ?.status === 'paid'
                                            "
                                        >
                                            Lunas
                                        </div>
                                        <div
                                            class="unpaid-badge rounded-pill d-inline px-3"
                                            v-else
                                        >
                                            Belum Lunas
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 fw-medium">
                                        Tanggal Pembayaran
                                    </td>
                                    <td>:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail?.payment_information?.paid_at
                                                ? detail?.payment_information
                                                      ?.paid_at
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12 my-3">
                        <div class="box-manifest">
                            <h5 class="fw-bold mb-0">
                                Bukti Pembayaran Vendor
                            </h5>
                            <table
                                class="w-100 ps-0 fw-bold mt-3"
                                cellpadding="10"
                            >
                                <tr>
                                    <td class="ps-0 fw-medium">
                                        Bukti Pembayaran
                                    </td>
                                    <td>:</td>
                                    <td>
                                        <div
                                            v-if="
                                                !detail?.payment_information
                                                    ?.attachment
                                            "
                                            class="box d-flex flex-column justify-content-center py-5 position-relative cursor-pointer h-10"
                                            @click="$refs.fileInput.click()"
                                            :class="{
                                                'padding-0': previewImage,
                                            }"
                                        >
                                            <input
                                                type="file"
                                                ref="fileInput"
                                                accept="image/*"
                                                class="d-none"
                                                @change="handleFileUpload"
                                            />
                                            <div
                                                class="text-center"
                                                v-if="!previewImage"
                                            >
                                                <img
                                                    src="../assets/upload-profile.svg"
                                                    alt="upload profile"
                                                />
                                                <p
                                                    class="text-gray text-center mb-0"
                                                >
                                                    Upload Bukti
                                                </p>
                                            </div>
                                            <div
                                                v-else
                                                class="profile-image-preview"
                                            >
                                                <img
                                                    :src="previewImage"
                                                    alt="Profile Preview"
                                                    class="img-fluid rounded border-1-rem"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            v-else
                                            class="box d-flex flex-column justify-content-center py-5 position-relative cursor-pointer h-10 padding-0"
                                        >
                                            <div class="profile-image-preview">
                                                <img
                                                    :src="
                                                        detail
                                                            ?.payment_information
                                                            ?.attachment
                                                    "
                                                    alt="Profile Preview"
                                                    class="img-fluid rounded border-1-rem"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <button
                                v-if="!detail?.payment_information?.attachment"
                                class="btn btn-primary w-100 mt-3"
                                :disabled="!previewImage"
                                @click="modalConfirm = true"
                            >
                                Simpan Bukti Pembayaran
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="sidebar-overlay"
            v-if="isSidebarOpen"
            @click="closeSidebar()"
        ></div>
        <div
            class="sidebar"
            v-if="isSidebarOpen"
            :class="{ 'sidebar-open': isSidebarOpen }"
        >
            <div class="sidebar-content">
                <div
                    class="d-flex justify-content-between align-items-center p-3 border-bottom sticky-header"
                >
                    <h5 class="mb-0 fw-bold">Detail Informasi Vendor</h5>
                    <div
                        type="button"
                        class="btn-close"
                        @click="closeSidebar()"
                    ></div>
                </div>
                <div class="p-3 sidebar-body">
                    <div v-if="isLoadingDetailVendor">
                        <div class="d-flex justify-content-center mt-5">
                            <img src="../assets/empty.svg" alt="empty" />
                        </div>
                        <p class="text-gray text-center mt-5">
                            Sedang mengambil data
                        </p>
                    </div>
                    <div v-else>
                        <h5
                            class="text-primary my-3 text-center text-capitalize"
                        >
                            {{ detailVendor?.name }}
                        </h5>
                        <div class="d-flex align-items-center mb-3">
                            <a
                                :href="'tel:' + detailVendor?.phone"
                                class="text-link d-flex align-items-center"
                            >
                                <img
                                    class="me-3"
                                    src="../assets/call.svg"
                                    alt="call"
                                />
                                <p class="mb-0">{{ detailVendor?.phone }}</p>
                            </a>
                        </div>
                        <div class="d-flex align-items-center mb-3">
                            <a
                                :href="'mailto:' + detailVendor?.email"
                                class="text-link d-flex align-items-center"
                            >
                                <img
                                    class="me-3"
                                    src="../assets/mail.svg"
                                    alt="mail"
                                />
                                <p class="mb-0">{{ detailVendor?.email }}</p>
                            </a>
                        </div>
                        <div class="d-flex align-items-center mb-3">
                            <img
                                class="me-3"
                                src="../assets/pin.svg"
                                alt="pin"
                            />
                            <p class="mb-0">{{ detailVendor?.address }}</p>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center mb-3">
                            <img
                                class="me-3"
                                src="../assets/person.svg"
                                alt="pin"
                            />
                            <p class="mb-0">{{ detailVendor?.pic }}</p>
                        </div>
                        <div class="d-flex align-items-center mb-3">
                            <img
                                class="me-3"
                                src="../assets/call.svg"
                                alt="pin"
                            />
                            <p class="mb-0">{{ detailVendor?.phone_pic }}</p>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center mb-3">
                            <img
                                class="me-3"
                                src="../assets/bank.svg"
                                alt="pin"
                            />
                            <p class="mb-0">
                                {{ detailVendor?.bank_name }} |
                                {{ detailVendor?.account_number }}
                            </p>
                        </div>
                        <div class="d-flex align-items-center mb-3">
                            <img
                                class="me-3"
                                src="../assets/id-card.svg"
                                alt="pin"
                            />
                            <p class="mb-0">{{ detailVendor?.account_name }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Bukti Pembayaran Vendor Akan Disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan bukti pembayaran sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isSave"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isSave"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="fw-bold mt-4">
                        Bukti Pembayaran Vendor Berhasil Disimpan
                    </h5>
                </div>
                <button
                    type="button"
                    @click="
                        modalSuccess = false;
                        getLists();
                    "
                    class="btn btn-primary w-100 mt-4 btn-lg"
                >
                    Tutup
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
export default {
    data() {
        return {
            isSidebarOpen: false,
            detail: null,
            isPageLoading: false,
            detailVendor: null,
            isLoadingDetailVendor: false,
            expandedSections: [],
            proofUrl: null,
            previewImage: null,
            selectedFile: null,
            isSave: false,
            modalConfirm: false,
            modalSuccess: false,
        };
    },
    created() {
        this.getDetail();
        this.expandedSections = [0];
    },
    methods: {
        storeData() {
            this.isSave = true;
            var data = {
                id: this.$route.params.id,
                attachment: this.proofUrl,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/payment/store`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isSave = false;
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.isSave = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;
                this.previewImage = URL.createObjectURL(file);
                this.uploadAvatar(file);
            }
        },
        uploadAvatar(file) {
            const formData = new FormData();
            formData.append("path", "disbursement_vendor");
            formData.append("file", file);

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/general/upload`,
                formData,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
                .then((res) => {
                    this.proofUrl = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.previewImage = null;
                    this.selectedFile = null;
                });
        },
        toggleSection(index) {
            if (this.expandedSections.includes(index)) {
                this.expandedSections = this.expandedSections.filter(
                    (i) => i !== index
                );
            } else {
                this.expandedSections.push(index);
            }
        },
        getDetailVendor(vendor_id) {
            this.isLoadingDetailVendor = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vendors/detail`, {
                params: {
                    vendor_id: vendor_id,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.detailVendor = res.data.data;
                    this.isLoadingDetailVendor = false;
                })
                .catch((err) => {
                    this.isLoadingDetailVendor = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDetail() {
            this.isPageLoading = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/payment/detail`,
                {
                    params: { id: this.$route.params.id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.isPageLoading = false;
                });
        },
        openSidebar(vendor_id) {
            this.isSidebarOpen = true;
            this.getDetailVendor(vendor_id);
        },
        closeSidebar() {
            this.isSidebarOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.padding-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.border-1-rem {
    border-radius: 1rem !important;
}
.profile-image-preview {
    position: relative;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .change-photo-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        text-align: center;
        padding: 10px;
        cursor: pointer;
    }
}
.rotate-180 {
    transform: rotate(180deg);
}
.bb-r {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.receipt-code {
    height: 100px;
    overflow-y: auto;
}
.sidebar-body {
    overflow-y: auto;
    padding-bottom: 120px !important;

    @media (max-width: 576px) {
        padding-bottom: 150px !important;
    }
}
.sticky-header {
    position: sticky;
    top: 0;
    background-color: #f9fafb;
    z-index: 1;
}
.sidebar-content {
    height: calc(100vh - 55px);
    display: flex;
    flex-direction: column;
    position: relative;
}
.sidebar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 500px;
    height: 100vh;
    background-color: #f9fafb;
    z-index: 1050;
    transition: right 0.3s ease-in-out;
    box-shadow: -8px 0 10px -6px rgba(0, 0, 0, 0.1);
    margin-top: 55px;

    @media (max-width: 576px) {
        margin-top: 0;
        width: 100%;
        max-width: 100%;
    }
}
.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1040;
}

@media (max-width: 576px) {
    .sidebar-overlay {
        background-color: rgba(0, 0, 0, 0.5);
    }
}
.sidebar-open {
    right: 0;
}
.h-10 {
    height: 10rem;
}
.unpaid-badge {
    border: 1px solid #ffcf99;
    background-color: #ffe7cc;
    color: #995100;
}
.paid-badge {
    border: 1px solid #b2e6c8;
    background-color: #d9f2e4;
    color: #267347;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    cursor: pointer;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.badge-darat {
    border: 1px solid #c2cdd6;
    background-color: #e0e6eb;
    color: #005299;
}
.badge-laut {
    border: 1px solid #f1eb74;
    background-color: #faf8d1;
    color: #8b850e;
}
.badge-udara {
    border: 1px solid #99d0ff;
    background-color: #cce7ff;
    color: #3e4e5c;
}
</style>

<template>
    <div>
        <div class="table-responsive-custom">
            <table class="table table-bordered mt-3">
                <thead class="table-light">
                    <tr>
                        <th class="fw-medium">
                            Koli <span class="text-danger">*</span>
                        </th>
                        <th class="fw-medium">
                            Deskripsi <span class="text-danger">*</span>
                        </th>
                        <th class="fw-medium">
                            Berat (Kg) <span class="text-danger">*</span>
                        </th>
                        <th class="fw-medium">
                            Panjang (Cm) <span class="text-danger">*</span>
                        </th>
                        <th class="fw-medium">
                            Lebar (Cm) <span class="text-danger">*</span>
                        </th>
                        <th class="fw-medium">
                            Tinggi (Cm) <span class="text-danger">*</span>
                        </th>
                        <th class="fw-medium">
                            Berat Volume (Kg) <span class="text-danger">*</span>
                        </th>
                        <th class="fw-medium">
                            Kubikasi (m3) <span class="text-danger">*</span>
                        </th>
                        <th class="fw-medium invisible">a</th>
                    </tr>
                </thead>
                <tbody
                    class="padding-body"
                    v-for="(item, index) in items"
                    :key="index"
                >
                    <tr>
                        <td class="custom">
                            <input
                                type="text"
                                placeholder="Koli"
                                class="form-control w-koli"
                                v-model="item.qty"
                                @input="
                                    formatNumber($event, index, 'qty', true)
                                "
                                @blur="validateNumber($event, index, 'qty')"
                            />
                        </td>
                        <td class="custom">
                            <input
                                type="text"
                                class="form-control w-desc"
                                placeholder="Deskripsi"
                                v-model="item.description"
                            />
                        </td>
                        <td class="custom">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Berat"
                                v-model="item.weight"
                                @input="
                                    formatNumber($event, index, 'weight', true)
                                "
                                @blur="validateNumber($event, index, 'weight')"
                                v-bind:class="{
                                    green:
                                        item.weight >
                                        Number(calculateVolumeWeight(item)),
                                }"
                            />
                        </td>
                        <td class="custom">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="P"
                                v-model="item.length"
                                @input="
                                    formatNumber($event, index, 'length', true)
                                "
                                @blur="validateNumber($event, index, 'length')"
                            />
                        </td>
                        <td class="custom">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="L"
                                v-model="item.width"
                                @input="
                                    formatNumber($event, index, 'width', true)
                                "
                                @blur="validateNumber($event, index, 'width')"
                            />
                        </td>
                        <td class="custom">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="T"
                                v-model="item.height"
                                @input="
                                    formatNumber($event, index, 'height', true)
                                "
                                @blur="validateNumber($event, index, 'height')"
                            />
                        </td>
                        <td class="custom">
                            <div
                                class="form-box"
                                v-bind:class="{
                                    green:
                                        item.weight <
                                        Number(calculateVolumeWeight(item)),
                                }"
                            >
                                {{
                                    formatDisplayResult(
                                        calculateVolumeWeight(item)
                                    )
                                }}
                            </div>
                        </td>
                        <td class="custom">
                            <div class="form-box">
                                {{ formatDisplayResult(calculateCubic(item)) }}
                            </div>
                        </td>
                        <td class="custom">
                            <div
                                class="d-flex flex-column justify-content-end"
                                style="height: 30px"
                                @click="openDeleteModal(index)"
                            >
                                <img
                                    src="../../assets/trash-yellow.png"
                                    width="16"
                                    alt="trash yellow"
                                    style="cursor: pointer"
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="custom" colspan="9">
                            <div class="w-430">
                                <div
                                    class="d-flex justify-content-between align-items-center"
                                >
                                    <label class="fw-medium"
                                        >Perlindungan Barang</label
                                    >
                                    <button
                                        class="btn btn-add d-flex align-items-center"
                                        v-if="
                                            item.protection &&
                                            item.protection.length > 0
                                        "
                                        @click="openProtectionModal(index)"
                                    >
                                        <img
                                            src="../../assets/pencil.svg"
                                            alt="pencil"
                                            class="me-2"
                                        />Edit
                                    </button>
                                    <button
                                        class="btn btn-add d-flex align-items-center"
                                        v-else
                                        @click="openProtectionModal(index)"
                                    >
                                        <img
                                            src="../../assets/plus.svg"
                                            alt="plus"
                                            class="me-2"
                                        />Tambah
                                    </button>
                                </div>
                                <div
                                    class="form-control mt-3"
                                    v-if="
                                        item.protection &&
                                        item.protection.length > 0
                                    "
                                >
                                    <div
                                        class="d-flex justify-content-between my-1"
                                        v-for="(
                                            protection, pIndex
                                        ) in item.protection"
                                        :key="pIndex"
                                    >
                                        <p class="mb-0">
                                            {{ protection.name }}
                                        </p>
                                        <p class="mb-0">
                                            {{ currency(protection.price) }}
                                        </p>
                                    </div>
                                </div>
                                <input
                                    v-else
                                    type="text"
                                    class="form-control mt-3"
                                    placeholder="Belum ada perlindungan barang ..."
                                    disabled
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <button class="btn btn-green px-4" @click="addNewItem">
                <div class="d-flex align-items-center">
                    <i class="pi pi-plus me-1"></i>
                    Tambah Barang
                </div>
            </button>
        </div>
        <hr class="mt-4" />
        <h5 class="fw-bold mb-0">Hasil</h5>
        <div class="table-responsive-custom">
            <table class="result table table-bordered mt-3">
                <thead class="table-light">
                    <tr>
                        <th scope="col" class="fw-medium">Berat Aktual (Kg)</th>
                        <th scope="col" class="fw-medium">Berat Volume (Kg)</th>
                        <th scope="col" class="fw-medium">Kubikasi (m3)</th>
                        <th scope="col" class="fw-medium">
                            Charge Weight (Kg)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="form-box">
                                {{ formatDisplayResult(totalActualWeight) }}
                            </div>
                        </td>
                        <td>
                            <div class="form-box">
                                {{ formatDisplayResult(totalVolumeWeight) }}
                            </div>
                        </td>
                        <td>
                            <div class="form-box">
                                {{ formatDisplayResult(totalCubic) }}
                            </div>
                        </td>
                        <td>
                            <div class="form-box green">
                                {{ formatDisplayResult(chargeWeight) }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr />
        <label class="fw-bold mb-2">Catatan</label>
        <textarea
            class="form-control"
            placeholder="Masukkan catatan untuk barang"
            v-model="note"
        ></textarea>
        <div class="modal-vue" v-if="modalDelete">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Data Ini Akan Dihapus</h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ukur-timbang
                        barang ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="closeDeleteModal"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="confirmDelete"
                        >
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalProtection">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <h5 class="mb-3">Pilih Perlindungan Barang</h5>
                <div
                    class="my-3"
                    v-for="(
                        protectionOption, index
                    ) in currentProtectionOptions"
                    :key="index"
                >
                    <div
                        class="d-flex justify-content-between align-items-center mb-3"
                    >
                        <div class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                class="me-3 cursor-pointer custom-checkbox"
                                :id="protectionOption.id"
                                v-model="protectionOption.selected"
                            />
                            <label
                                :for="protectionOption.id"
                                class="cursor-pointer"
                            >
                                {{ protectionOption.name }}
                            </label>
                        </div>
                        {{ currency(protectionOption.price) }}
                    </div>
                    <hr />
                </div>
                <div class="d-flex justify-content-end">
                    <button
                        class="btn btn-primary px-5"
                        @click="saveProtections"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import helpers from "@/helpers/index.js";
export default {
    name: "Goods",
    props: {
        defaultData: {
            type: Object,
            default: () => ({
                items: [
                    {
                        qty: "",
                        description: "",
                        weight: "",
                        length: "",
                        width: "",
                        height: "",
                        protection: [],
                        protectionOptions: [
                            {
                                name: "Wrapping Plastic",
                                id: "wrapping_plastic",
                                basePrice: 5000,
                                selected: false,
                                price: 0,
                            },
                            {
                                name: "Pallet",
                                id: "pallet",
                                basePrice: 60000,
                                selected: false,
                                price: 0,
                            },
                            {
                                name: "Bubble Wrap",
                                id: "bubble_wrap",
                                basePrice: 10000,
                                selected: false,
                                price: 0,
                            },
                            {
                                name: "Kardus",
                                id: "kardus",
                                basePrice: 10000,
                                selected: false,
                                price: 0,
                            },
                            {
                                name: "Kayu",
                                id: "kayu",
                                basePrice: 60000,
                                selected: false,
                                price: 0,
                            },
                            {
                                name: "Karung Kecil",
                                id: "karung_kecil",
                                basePrice: 7500,
                                selected: false,
                                price: 0,
                            },
                            {
                                name: "Karung Sedang",
                                id: "karung_sedang",
                                basePrice: 10000,
                                selected: false,
                                price: 0,
                            },
                            {
                                name: "Karung Besar",
                                id: "karung_besar",
                                basePrice: 12500,
                                selected: false,
                                price: 0,
                            },
                        ],
                    },
                ],
                note: "",
            }),
        },
    },
    data() {
        return {
            items: [],
            note: "",
            modalDelete: false,
            modalProtection: false,
            selectedItemIndex: null,
            currentProtectionOptions: [],
            emptyProtections: [
                {
                    name: "Wrapping Plastic",
                    id: "wrapping_plastic",
                    basePrice: 5000,
                    selected: false,
                    price: 0,
                },
                {
                    name: "Pallet",
                    id: "pallet",
                    basePrice: 60000,
                    selected: false,
                    price: 0,
                },
                {
                    name: "Bubble Wrap",
                    id: "bubble_wrap",
                    basePrice: 10000,
                    selected: false,
                    price: 0,
                },
                {
                    name: "Kardus",
                    id: "kardus",
                    basePrice: 10000,
                    selected: false,
                    price: 0,
                },
                {
                    name: "Kayu",
                    id: "kayu",
                    basePrice: 60000,
                    selected: false,
                    price: 0,
                },
                {
                    name: "Karung Kecil",
                    id: "karung_kecil",
                    basePrice: 7500,
                    selected: false,
                    price: 0,
                },
                {
                    name: "Karung Sedang",
                    id: "karung_sedang",
                    basePrice: 10000,
                    selected: false,
                    price: 0,
                },
                {
                    name: "Karung Besar",
                    id: "karung_besar",
                    basePrice: 12500,
                    selected: false,
                    price: 0,
                },
            ],
        };
    },
    created() {
        if (this.defaultData) {
            this.items = this.defaultData.items.map((item) => ({
                ...item,
                protectionOptions:
                    item.protectionOptions ||
                    this.emptyProtections.map((p) => ({ ...p })),
            }));
            this.note = this.defaultData.note;
        }
    },
    computed: {
        formData() {
            return {
                items: this.items.map((item) => ({
                    ...item,
                    volumeWeight: this.calculateVolumeWeight(item),
                    cubic: this.calculateCubic(item),
                })),
                note: this.note,
                totalActualWeight: this.totalActualWeight,
                totalVolumeWeight: this.totalVolumeWeight,
                totalCubic: this.totalCubic,
                chargeWeight: this.chargeWeight,
            };
        },
        totalActualWeight() {
            return this.items
                .reduce((sum, item) => {
                    const weight = this.parseFormattedNumber(item.weight) || 0;
                    const qty = this.parseFormattedNumber(item.qty) || 1;
                    return sum + weight * qty;
                }, 0)
                .toFixed(2);
        },
        totalVolumeWeight() {
            const total = this.items.reduce((sum, item) => {
                const volumeWeight = this.calculateVolumeWeight(item);
                return sum + Math.round(volumeWeight);
            }, 0);

            let roundedTotal;
            if (total >= 1000) {
                roundedTotal = Math.round(total / 1000) * 1000;
            } else {
                roundedTotal = total;
            }
            return roundedTotal.toFixed(2);
        },
        totalCubic() {
            return this.items
                .reduce((sum, item) => {
                    const cubic = this.calculateCubic(item);
                    return sum + parseFloat(cubic);
                }, 0)
                .toFixed(2);
        },
        chargeWeight() {
            const actualWeight = parseFloat(this.totalActualWeight);
            const volumeWeight = parseFloat(this.totalVolumeWeight);
            return Math.max(actualWeight, volumeWeight).toFixed(2);
        },
    },
    methods: {
        emitDataChanges() {
            this.$emit("update:data", this.formData);
        },
        checkAndUpdateQty(index) {
            const item = this.items[index];
            if (
                item.description ||
                item.weight ||
                item.length ||
                item.width ||
                item.height ||
                (item.protection && item.protection.length > 0)
            ) {
                if (!item.qty) {
                    this.$set(this.items[index], "qty", "1");
                }
            }
        },
        updateProtectionPrices(itemIndex) {
            const item = this.items[itemIndex];
            if (!item || !item.protection || item.protection.length === 0)
                return;
            const dimensions = this.getDimensions(item);
            const updatedProtection = item.protection.map((protection) => ({
                ...protection,
                price: helpers.calculatePackingCost(protection.id, dimensions),
            }));
            this.$set(this.items[itemIndex], "protection", updatedProtection);
        },
        openProtectionModal(index) {
            this.selectedItemIndex = index;
            this.modalProtection = true;
            const item = this.items[index];
            const dimensions = this.getDimensions(item);
            this.currentProtectionOptions = item.protectionOptions.map(
                (option) => {
                    const calculatedPrice = helpers.calculatePackingCost(
                        option.id,
                        dimensions
                    );
                    const existingProtection = item.protection.find(
                        (p) => p.id === option.id
                    );
                    return {
                        ...option,
                        price: calculatedPrice,
                        selected: existingProtection !== undefined,
                    };
                }
            );
        },
        closeProtectionModal() {
            this.modalProtection = false;
            this.selectedItemIndex = null;
            this.currentProtectionOptions = [];
        },
        saveProtections() {
            if (this.selectedItemIndex !== null) {
                const selectedProtections = this.currentProtectionOptions
                    .filter((option) => option.selected)
                    .map((option) => ({
                        id: option.id,
                        name: option.name,
                        price: option.price,
                    }));
                this.$set(
                    this.items[this.selectedItemIndex],
                    "protection",
                    selectedProtections
                );
                this.$set(
                    this.items[this.selectedItemIndex],
                    "protectionOptions",
                    this.currentProtectionOptions.map((option) => ({
                        ...option,
                        price: option.price,
                    }))
                );
                this.checkAndUpdateQty(this.selectedItemIndex);
                this.closeProtectionModal();
                this.emitDataChanges();
            }
        },
        addNewItem() {
            const newProtectionOptions = JSON.parse(
                JSON.stringify(this.emptyProtections)
            );
            this.items.push({
                qty: "",
                description: "",
                weight: "",
                length: "",
                width: "",
                height: "",
                protection: [],
                protectionOptions: newProtectionOptions,
            });
            this.emitDataChanges();
        },
        openDeleteModal(index) {
            this.selectedItemIndex = index;
            this.modalDelete = true;
        },
        closeDeleteModal() {
            this.modalDelete = false;
            this.selectedItemIndex = null;
        },
        confirmDelete() {
            if (this.selectedItemIndex !== null) {
                this.items.splice(this.selectedItemIndex, 1);
            }
            this.closeDeleteModal();
            this.emitDataChanges();
        },
        formatDisplayResult(number) {
            if (!number || isNaN(number)) return "0,00";
            const num =
                typeof number === "string" ? parseFloat(number) : number;
            const [intPart, decPart] = num.toFixed(2).split(".");
            const formattedIntPart = parseInt(intPart)
                .toLocaleString("id-ID")
                .replace(/,/g, ".");
            return `${formattedIntPart},${decPart}`;
        },
        formatNumber(event, index, field, isInput = false) {
            let value = event.target.value;
            value = value.replace(/\./g, "");
            if (
                isInput &&
                (event.inputType === "deleteContentBackward" ||
                    event.inputType === "deleteContentForward")
            ) {
                if (!value) {
                    this.items[index][field] = "";
                    this.checkAndUpdateQty(index);
                    return;
                }
            }
            value = value.replace(/[^\d,]/g, "");
            const parts = value.split(",");
            if (parts.length > 2) {
                value = parts[0] + "," + parts.slice(1).join("");
            }
            if (!value) {
                this.items[index][field] = "";
                this.checkAndUpdateQty(index);
                return;
            }
            const numericValue = parseFloat(value.replace(",", "."));
            if (!isNaN(numericValue)) {
                let formattedValue = numericValue
                    .toLocaleString("id-ID", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                    })
                    .replace(/,/g, ".");
                this.items[index][field] = formattedValue;
                this.checkAndUpdateQty(index);
            }
        },
        validateNumber(event, index, field) {
            let value = event.target.value;
            if (!value) {
                this.items[index][field] = "";
                return;
            }
            const number = this.parseFormattedNumber(value);
            if (!isNaN(number)) {
                this.items[index][field] = this.formatInputNumber(number);
            } else {
                this.items[index][field] = "";
            }
        },
        parseFormattedNumber(value) {
            if (!value || typeof value !== "string") return 0;
            return parseFloat(value.replace(/\./g, "").replace(",", "."));
        },
        formatInputNumber(number) {
            if (number === 0 || isNaN(number)) return "";
            return number
                .toLocaleString("id-ID", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                    useGrouping: true,
                })
                .replace(/,/g, ".");
        },
        formatResult(number) {
            if (number === 0 || isNaN(number)) return "0";
            return number.toString().replace(/\./g, ",");
        },
        calculateVolumeWeight(item) {
            const length = this.parseFormattedNumber(item.length) || 0;
            const width = this.parseFormattedNumber(item.width) || 0;
            const height = this.parseFormattedNumber(item.height) || 0;
            const qty = this.parseFormattedNumber(item.qty) || 1;
            return (((length * width * height) / 4000) * qty).toFixed(2);
        },
        calculateCubic(item) {
            const length = this.parseFormattedNumber(item.length) || 0;
            const width = this.parseFormattedNumber(item.width) || 0;
            const height = this.parseFormattedNumber(item.height) || 0;
            const qty = this.parseFormattedNumber(item.qty) || 1;
            return (((length * width * height) / 1000000) * qty).toFixed(2);
        },
        getDimensions(item) {
            return {
                P: this.parseFormattedNumber(item.length) || 0,
                L: this.parseFormattedNumber(item.width) || 0,
                T: this.parseFormattedNumber(item.height) || 0,
                K: this.parseFormattedNumber(item.qty) || 1,
            };
        },
    },
    watch: {
        items: {
            deep: true,
            handler(newItems) {
                newItems.forEach((item, index) => {
                    this.checkAndUpdateQty(index);
                    const dimensionKey = `${item.qty}-${item.length}-${item.width}-${item.height}`;
                    if (!item._lastDimensionKey) {
                        this.$set(item, "_lastDimensionKey", dimensionKey);
                        return;
                    }
                    if (item._lastDimensionKey !== dimensionKey) {
                        this.updateProtectionPrices(index);
                        this.$set(item, "_lastDimensionKey", dimensionKey);
                    }
                });
                this.emitDataChanges();
            },
        },
        note: {
            handler() {
                this.emitDataChanges();
            },
        },
        defaultData: {
            handler(newData) {
                if (newData) {
                    this.items = newData.items.map((item) => ({
                        ...item,
                        protectionOptions:
                            item.protectionOptions ||
                            this.emptyProtections.map((p) => ({ ...p })),
                    }));
                    this.note = newData.note;
                }
            },
            deep: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #c1cdd7;
    background-color: white;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}
.custom-checkbox:checked {
    border: 1px solid #329a5f;
    background-color: #d9f2e4;
}
.custom-checkbox:checked::after {
    content: "✔";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #329a5f;
    font-size: 14px;
}
hr.modal-hr {
    color: #c1cdd7 !important;
    margin: 0;
}
.modal-vue .modal-body-chat.vsm {
    width: 450px;
}
.table > :not(caption) > * > * {
    border: unset;
}
.table-bordered > :not(caption) > * {
    border: unset;
}
.table > :not(caption) > * > * {
    background-color: unset;
}
tr {
    border-radius: 10px !important;
}
tr td.custom {
    background-color: #ebf6ff !important;
}
.w-handling {
    width: 300px;
}
table tbody tr:first-child td:first-child {
    border-top-left-radius: 6px;
}
table tbody tr:first-child td:last-child {
    border-top-right-radius: 6px;
}
table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}
table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.result.table > :not(caption) > * > * {
    padding: 0.2rem 0.5rem;
}
.w-430 {
    width: 430px;
}
.btn-add {
    color: #40bf76 !important;
    background-color: transparent !important;
    border: 1px solid #40bf76 !important;
}
textarea {
    resize: none;
}
.form-control.green {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
</style>

<template>
    <div class="row">
        <div class="mt-3 col-lg-12">
            <label class="mb-2"
                >Tipe Mobil <span class="text-yellow">*</span></label
            >
            <input
                type="text"
                class="form-control"
                v-model="carType"
                @input="handleTypeSearch()"
                @focus="handleFocus()"
                placeholder="Masukkan tipe mobil"
            />
            <div class="suggestions-container" v-if="showSuggestions">
                <p class="suggestion-title">Pilih atau Tambahkan Tipe Mobil</p>
                <div class="suggestion-item-container">
                    <div
                        class="suggestion-item-none"
                        v-if="typeSuggestions.length === 0"
                    >
                        <i>Tipe mobil tidak ditemukan</i>
                    </div>
                    <div
                        v-else
                        v-for="(suggestion, index) in typeSuggestions"
                        :key="index"
                        class="suggestion-item"
                        @click="selectType(suggestion)"
                    >
                        {{ suggestion.name }}
                    </div>
                </div>
                <hr class="hr-suggestion" />
                <p class="suggestion-title d-flex align-items-center">
                    <span>Tambahkan:</span>
                    <span
                        v-if="isAddVehicle"
                        class="spinner-border text-primary ms-2"
                        role="status"
                    >
                    </span>
                    <span
                        v-else
                        class="text-suggestion-dark of-y pe-3 ps-1 cursor-pointer"
                        @click="createNewVehicle()"
                        >{{ carType }}</span
                    >
                </p>
            </div>
        </div>
        <div class="col-lg-12 mt-3">
            <label class="mb-2">Deskripsi</label>
            <textarea
                class="form-control"
                placeholder="Masukkan deskripsi untuk mobil"
                v-model="carDescription"
            ></textarea>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
export default {
    props: {
        defaultData: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            carType: "",
            showSuggestions: false,
            typeSuggestions: [],
            isAddVehicle: false,
            carDescription: "",
        };
    },
    computed: {
        formData() {
            const data = {
                vehicle: this.carType,
                description: this.carDescription,
            };
            return data;
        },
    },
    created() {
        document.addEventListener("click", (e) => {
            const suggestionsContainer = document.querySelector(
                ".suggestions-container"
            );
            const typeInput = document.querySelector(
                'input[placeholder="Masukkan tipe mobil"]'
            );

            if (suggestionsContainer && typeInput) {
                if (
                    !suggestionsContainer.contains(e.target) &&
                    !typeInput.contains(e.target)
                ) {
                    this.showSuggestions = false;
                }
            }
        });
        if (this.defaultData) {
            this.carType = this.defaultData.vehicle || "";
            this.carDescription = this.defaultData.description || "";
        }
    },
    methods: {
        emitDataChanges() {
            this.$emit("update:data", this.formData);
        },
        handleTypeSearch() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(() => {
                this.getListVehicle();
            }, 300);
        },
        handleFocus() {
            this.handleTypeSearch();
            this.showSuggestions = true;
        },
        createNewVehicle() {
            this.isAddVehicle = true;
            const payload = {
                name: this.carType,
                type: "car",
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vehicles/create`,
                payload,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isAddVehicle = false;
                    this.handleTypeSearch();
                })
                .catch((err) => {
                    this.isAddVehicle = false;
                    this.showSuggestions = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getListVehicle() {
            const payload = {
                search: this.carType,
                type: "car",
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vehicles`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.typeSuggestions = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        selectType(suggestion) {
            this.carType = suggestion.name;
            this.showSuggestions = false;
        },
    },
    watch: {
        carType: {
            handler() {
                this.emitDataChanges();
            },
        },
        carDescription: {
            handler() {
                this.emitDataChanges();
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.of-y::-webkit-scrollbar {
    display: none;
}
hr.hr-suggestion {
    margin: 0;
    margin-top: 5px;
}
.suggestions-container {
    position: static;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #c1cdd7;
    border-radius: 7px;
    margin-top: 2px;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.suggestion-title {
    padding: 8px 12px !important;
    margin-bottom: 0 !important;
    font-size: 12px;
    color: #a3b4c2;
}
.text-suggestion-dark {
    color: #212529;
    font-weight: 500;
}
.suggestion-item-container {
    max-height: 200px;
    overflow-y: auto;
}
.suggestion-item {
    padding: 5px 24px;
    cursor: pointer;

    &:hover {
        background-color: #f3f5f7;
    }
}
.suggestion-item-none {
    padding: 5px 24px;
}
.hr-suggestion {
    margin: 0;
    margin-top: 5px;
}
textarea {
    resize: none;
}
</style>

<template>
    <div class="padding-container">
        <div class="content-load" v-if="detaiLoading"></div>
        <div id="loader" v-if="detaiLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <div class="row">
                <div class="col-lg-6 my-2">
                    <label class="fw-bold mb-2"
                        >Peran <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="role"
                        :options="roles"
                        label="role"
                        track-by="role"
                        placeholder="Pilih peran user"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-6 my-2">
                    <label class="fw-bold mb-2">
                        Nomor Handphone
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nomor"
                            v-model="phone"
                            @input="validatePhone()"
                        />
                    </div>
                    <div
                        v-if="phoneError"
                        class="d-flex align-items-center mt-2"
                    >
                        <img
                            src="../assets/mini-warning.svg"
                            alt="mini warning"
                            class="me-2"
                        />
                        <small class="text-yellow">{{ phoneError }}</small>
                    </div>
                </div>
                <div class="col-lg-6 my-2">
                    <label class="fw-bold mb-2"
                        >Nama <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="name"
                        placeholder="Masukkan nama"
                    />
                </div>
                <div class="col-lg-6 my-2">
                    <label class="fw-bold mb-2"
                        >Email <span class="text-yellow">*</span></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        v-model="email"
                        placeholder="Masukkan email"
                        @input="validateEmail()"
                    />
                    <div
                        v-if="emailError"
                        class="d-flex align-items-center mt-2"
                    >
                        <img
                            src="../assets/mini-warning.svg"
                            alt="mini warning"
                            class="me-2"
                        />
                        <small class="text-yellow">{{ emailError }}</small>
                    </div>
                </div>
                <div class="col-lg-6 my-2">
                    <label class="fw-bold mb-2">Password Akun </label>
                    <div class="password-container">
                        <input
                            :type="showPassword ? 'text' : 'password'"
                            class="form-control"
                            v-model="password"
                            placeholder="Buat password akun"
                            @input="validatePassword()"
                        />
                        <div class="eye-icons">
                            <img
                                :src="showPassword ? eyeShowIcon : eyeHideIcon"
                                :alt="showPassword ? 'eye show' : 'eye hide'"
                                @click="() => togglePasswordVisibility()"
                                class="eye-icon"
                            />
                        </div>
                    </div>
                    <div
                        v-if="passwordError"
                        class="d-flex align-items-center mt-2"
                    >
                        <img
                            src="../assets/mini-warning.svg"
                            alt="mini warning"
                            class="me-2"
                        />
                        <small class="text-yellow">{{ passwordError }}</small>
                    </div>
                </div>
                <div class="col-lg-6 my-2">
                    <label class="fw-bold mb-2">Konfirmasi Password Akun</label>
                    <div class="password-container">
                        <input
                            :type="showConfirmPassword ? 'text' : 'password'"
                            class="form-control"
                            v-model="passwordConfirmation"
                            placeholder="Konfirmasi password akun"
                            @input="validatePasswordConfirmation"
                        />
                        <div class="eye-icons">
                            <img
                                :src="
                                    showConfirmPassword
                                        ? eyeShowIcon
                                        : eyeHideIcon
                                "
                                :alt="
                                    showConfirmPassword
                                        ? 'eye show'
                                        : 'eye hide'
                                "
                                @click="() => toggleConfirmPasswordVisibility()"
                                class="eye-icon"
                            />
                        </div>
                    </div>
                    <div
                        class="d-flex align-items-center mt-2"
                        v-if="passwordConfirmationError"
                    >
                        <img
                            src="../assets/mini-warning.svg"
                            alt="mini warning"
                            class="me-2"
                        />
                        <small class="text-yellow">{{
                            passwordConfirmationError
                        }}</small>
                    </div>
                </div>
                <div class="col-lg-6 my-2">
                    <label class="fw-bold mb-2"
                        >Cabang <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="branch"
                        :options="branches"
                        label="name"
                        track-by="id"
                        placeholder="Pilih cabang"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-12 my-2">
                    <div class="row">
                        <div class="col-lg-3">
                            <label class="fw-bold mb-2">Foto Profile</label>
                            <div
                                class="box d-flex flex-column justify-content-center py-5 position-relative cursor-pointer h-14"
                                :class="{
                                    'padding-0': profileImage,
                                }"
                                @click="triggerFileInput()"
                            >
                                <input
                                    type="file"
                                    :ref="`fileInput`"
                                    @change="handleFileUpload($event)"
                                    accept="image/*"
                                    class="d-none"
                                />
                                <div v-if="!profileImage" class="text-center">
                                    <img
                                        src="../assets/upload-profile.svg"
                                        alt="upload profile"
                                    />
                                    <p class="text-gray text-center mb-0">
                                        Upload foto profile
                                    </p>
                                </div>
                                <div v-else class="profile-image-preview">
                                    <img
                                        :src="profileImage"
                                        alt="Profile Preview"
                                        class="img-fluid rounded border-1-rem"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 my-2 d-flex justify-content-end mt-4">
                    <button
                        class="btn btn-create px-5"
                        :disabled="!isFormValid"
                        @click="editAccount = true"
                    >
                        Simpan Perubahan
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="editAccount">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Perubahan akun user akan disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <button
                            class="btn btn-outline-yellow w-100 btn-lg"
                            @click="editAccount = false"
                        >
                            Kembali
                        </button>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="storeData()"
                            :disabled="isSave"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isSave"
                            ></span>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="editSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Perubahan akun user berhasil disimpan
                    </h5>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/user-management"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import eyeHideIcon from "@/assets/eye-hide.svg";
import eyeShowIcon from "@/assets/eye-show.svg";
import Api from "../api/Api";

export default {
    components: {
        Multiselect,
    },
    computed: {
        isFormValid() {
            return (
                this.role !== null &&
                this.name !== "" &&
                this.email !== "" &&
                this.phone !== "" &&
                this.phoneError === "" &&
                this.emailError === "" &&
                this.passwordError === "" &&
                this.passwordConfirmationError === "" &&
                this.branch !== null
            );
        },
    },
    created() {
        this.initializeData();
    },
    data() {
        return {
            role: null,
            name: "",
            email: "",
            phone: "",
            password: "",
            passwordConfirmation: "",
            branch: null,
            branches: [],
            showPassword: false,
            showConfirmPassword: false,
            profileImage: null,
            avatarUrl: null,
            eyeHideIcon,
            eyeShowIcon,
            roles: [],
            editSuccess: false,
            editAccount: false,
            detaiLoading: false,
            emailDefault: "",
            phoneDefault: "",
            phoneError: "",
            emailError: "",
            passwordError: "",
            passwordConfirmationError: "",
            isSave: false,
        };
    },
    methods: {
        async initializeData() {
            this.detaiLoading = true;
            try {
                await Promise.all([this.getRoles(), this.getBranches()]);
                await this.getDetail();
            } catch (error) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text:
                        error.response?.data?.message ||
                        "An error occurred while initializing data",
                });
            } finally {
                this.detaiLoading = false;
            }
        },
        storeData() {
            this.isSave = true;
            const itemsData = {
                id: this.$route.params.id,
                role_id: this.role.role,
                name: this.name,
                phone: this.phone,
                email: this.email,
                avatar: this.avatarUrl,
                password: this.password,
                password_confirmation: this.passwordConfirmation,
                branch_id: this.branch.id,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/user_management/update`,
                itemsData,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isSave = false;
                    this.editSuccess = true;
                    this.editAccount = false;
                })
                .catch((err) => {
                    this.isSave = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        async getBranches() {
            const response = await Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/branch`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            this.branches = response.data.data;
        },
        triggerFileInput() {
            this.$refs[`fileInput`].click();
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                this.profileImage = e.target.result;

                this.uploadAvatar(file);
            };
            reader.readAsDataURL(file);
        },
        uploadAvatar(file) {
            const formData = new FormData();
            formData.append("path", "user_avatar");
            formData.append("file", file);

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/general/upload`,
                formData,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
                .then((res) => {
                    this.avatarUrl = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        validatePassword() {
            if (this.password.length < 6) {
                this.passwordError =
                    "Password harus terdiri dari min. 6 karakter";
            } else {
                this.passwordError = "";
            }
            if (this.passwordConfirmation) {
                this.validatePasswordConfirmation(this);
            }
        },
        validatePasswordConfirmation() {
            if (this.password !== this.passwordConfirmation) {
                this.passwordConfirmationError = "Password tidak sesuai";
            } else {
                this.passwordConfirmationError = "";
            }
        },
        validatePhone() {
            if (this.phone === "") {
                this.phone = "8" + this.phone.replace(/[^0-9]/g, "");
            } else {
                this.phone = this.phone.replace(/[^0-9]/g, "");
            }
            if (this.phone === this.phoneDefault) {
                this.phoneError = "";
            } else {
                this.phoneError = "";
                this.checkPhoneAvailability(this.phone);
            }
        },
        validateEmail() {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(this.email)) {
                this.emailError = "Format email salah";
            } else {
                if (this.email === this.emailDefault) {
                    this.emailError = "";
                } else {
                    this.emailError = "";
                    this.checkEmailAvailability(this.email);
                }
            }
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        toggleConfirmPasswordVisibility() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },
        checkEmailAvailability(value) {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/user_management/check/email`,
                {
                    params: { email: value },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    if (res.data.data === true) {
                        this.emailError = "Email sudah terdaftar";
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        checkPhoneAvailability(value) {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/user_management/check/phone`,
                {
                    params: { phone: value },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    if (res.data.data === true) {
                        this.phoneError = "Nomor handphone sudah terdaftar";
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        async getRoles() {
            const response = await Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/user_management/limit`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            this.roles = response.data.data;
        },
        async getDetail() {
            const response = await Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/user_management/detail`,
                {
                    params: { id: this.$route.params.id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            const detail = response.data.data;

            this.roles = this.roles.filter(
                (role) => role.limit > 0 || role.role === detail.role
            );

            this.role = this.roles.find((r) => r.role === detail.role) || null;
            this.name = detail.name || "";
            this.email = detail.email || "";
            this.phone = detail.phone || "";
            this.password = "";
            this.passwordConfirmation = "";
            this.phoneDefault = detail.phone || "";
            this.emailDefault = detail.email || "";
            this.profileImage = detail.avatar;
            this.branch =
                this.branches.find((r) => r.name === detail.branch) || null;
        },
    },
};
</script>

<style lang="scss" scoped>
.border-1-rem {
    border-radius: 1rem !important;
}
.h-14 {
    height: 14rem;
}
.padding-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.profile-image-preview {
    position: relative;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .change-photo-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        text-align: center;
        padding: 10px;
        cursor: pointer;
    }
}
.btn-create {
    background-color: #1291ff;
    color: #ffffff;
}
.btn-create:hover {
    background-color: #1291ff;
    color: #ffffff;
}
.btn-create:disabled {
    background-color: #849bae;
    color: #ffffff;
    cursor: not-allowed;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.password-container {
    position: relative;
    .eye-icons {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    .eye-icon {
        width: 20px;
        height: 20px;
    }
    input {
        padding-right: 40px;
    }
}
</style>

<template>
    <div
        class="page-wrapper chiller-theme"
        v-bind:class="!isMobile ? 'toggled' : ''"
    >
        <notifications group="foo" position="top right" />
        <div class="btn btn-sm btn-primary" id="show-sidebar">
            <i class="fa fa-angle-right"></i>
        </div>
        <nav id="sidebar" class="sidebar-wrapper">
            <div class="sidebar-content">
                <div class="sidebar-brand d-block d-lg-none">
                    <div id="close-sidebar">
                        <img src="../assets/arrow-back.png" width="30" alt="" />
                    </div>
                </div>
                <div class="sidebar-header">
                    <div
                        class="d-flex align-items-center justify-content-center"
                    >
                        <img
                            src="../assets/logo-klik.png"
                            width="150"
                            alt="logo klik"
                        />
                    </div>
                </div>
                <!-- <hr class="hr-horizontal mb-3" /> -->
                <div class="sidebar-menu">
                    <ul>
                        <li
                            class="sidebar-dropdown"
                            v-if="isRole(dashboardFinance)"
                        >
                            <router-link
                                to="/dashboard/finance"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'dashboard-finance',
                                }"
                            >
                                <i class="pi pi-th-large"></i>
                                <span>Dashboard</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-if="isRole(dashboardHeadDriver)"
                        >
                            <router-link
                                to="/dashboard/head-driver"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                        'dashboard-head-driver',
                                }"
                            >
                                <i class="pi pi-th-large"></i>
                                <span>Dashboard</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-if="isRole(dashboardTally)"
                        >
                            <router-link
                                to="/dashboard/tally"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'dashboard-tally',
                                }"
                            >
                                <i class="pi pi-th-large"></i>
                                <span>Dashboard</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-if="isRole(dashboardOpsManager)"
                        >
                            <router-link
                                to="/dashboard/ops-manager"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                        'dashboard-ops-manager',
                                }"
                            >
                                <i class="pi pi-th-large"></i>
                                <span>Dashboard</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-if="isRole(dashboardCrmManager)"
                        >
                            <router-link
                                to="/dashboard/manager"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'dashboard-manager',
                                }"
                            >
                                <i class="pi pi-th-large"></i>
                                <span>Dashboard</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-if="isRole(dashboardCrmSpecialist)"
                        >
                            <router-link
                                to="/dashboard/specialist"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                        'dashboard-specialist',
                                }"
                            >
                                <i class="pi pi-th-large"></i>
                                <span>Dashboard</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="
                                isRole(dataLeads) &&
                                (tier == 'gold' ||
                                    tier == 'platinum' ||
                                    tier == 'enterprise')
                            "
                        >
                            <router-link
                                to="/data/leads"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                            'crm-customers-leads' ||
                                        this.$route.name ==
                                            'crm-customers-leads-detail' ||
                                        this.$route.name ==
                                            'crm-customers-leads-prospect' ||
                                        this.$route.name ==
                                            'crm-customers-form' ||
                                        this.$route.name ==
                                            'crm-customers-form-edit' ||
                                        this.$route.name ==
                                            'crm-customers-leads-tags',
                                }"
                            >
                                <i class="pi pi-folder"></i>
                                <span>Data Leads</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="isRole(headDriver) && tier == 'enterprise'"
                            :class="{
                                '': true,
                                active: this.$route.name == 'assignment-driver',
                            }"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'assignment-driver'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'assignment-list'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'assignment-detail'
                                        ? 'active'
                                        : ''
                                "
                                @click="toggleSubmenu('headDriver')"
                            >
                                <i class="pi pi-user"></i>
                                <span>Head Driver</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                v-show="activeSubmenu === 'headDriver'"
                            >
                                <ul>
                                    <li>
                                        <router-link
                                            to="/assignment/driver"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'assignment-driver',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Penugasan Driver
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/assignment/list"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'assignment-list' ||
                                                    this.$route.name ==
                                                        'assignment-detail',
                                            }"
                                        >
                                            <i class="fa fa-circle sub"></i>
                                            Daftar Penugasan
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="isRole(vendor)"
                            :class="{
                                '': true,
                                active:
                                    this.$route.name == 'vendor-management' ||
                                    this.$route.name ==
                                        'vendor-management-add' ||
                                    this.$route.name ==
                                        'vendor-management-edit' ||
                                    this.$route.name == 'route' ||
                                    this.$route.name == 'route-add',
                            }"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'vendor-management'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'vendor-management-add'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'vendor-management-edit'
                                        ? 'active'
                                        : '' || this.$route.name == 'route'
                                        ? 'active'
                                        : '' || this.$route.name == 'route-add'
                                        ? 'active'
                                        : ''
                                "
                                @click="toggleSubmenu('vendor')"
                            >
                                <i class="pi pi-send"></i>
                                <span>Vendor</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                v-show="activeSubmenu === 'vendor'"
                            >
                                <ul>
                                    <li>
                                        <router-link
                                            to="/vendor-management"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'vendor-management' ||
                                                    this.$route.name ==
                                                        'vendor-management-add' ||
                                                    this.$route.name ==
                                                        'vendor-management-edit',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Manajemen Vendor
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/route"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                        'route' ||
                                                    this.$route.name ==
                                                        'route-add',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Rute & Keberangkatan
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="
                                isRole(followUp) &&
                                (tier == 'gold' ||
                                    tier == 'platinum' ||
                                    tier == 'enterprise')
                            "
                            :class="{
                                '': true,
                                active:
                                    this.$route.name == 'crm-prospect-today',
                            }"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'crm-prospect-today'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-tomorrow'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'crm-prospect-all'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-two-days'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-week'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-month'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-need-follow-up'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-detail'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-prediction'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-lost'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-deal'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prediction-edit'
                                        ? 'active'
                                        : ''
                                "
                                @click="toggleSubmenu('followUp')"
                            >
                                <i class="pi pi-send"></i>
                                <span>Follow Up</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                v-show="activeSubmenu === 'followUp'"
                            >
                                <ul>
                                    <li>
                                        <router-link
                                            to="/followup/all"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-all',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Semua Follow Up
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary tag-active':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-all',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-all',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.all
                                                                ? prospect_count?.all
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/followup/today"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-today',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Hari Ini
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary tag-active':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-today',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-today',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.today
                                                                ? prospect_count?.today
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/followup/tomorrow"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-tomorrow',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Kemarin
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary tag-active':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-tomorrow',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-tomorrow',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.yesterday
                                                                ? prospect_count?.yesterday
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/followup/next-two-days"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-next-two-days',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    2 Hari Lalu
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary tag-active':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-next-two-days',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-next-two-days',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.twodays
                                                                ? prospect_count?.twodays
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/followup/next-week"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-next-week',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Minggu Lalu
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary tag-active':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-next-week',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-next-week',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.lastweek
                                                                ? prospect_count?.lastweek
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/followup/next-month"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-next-month',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Bulan Lalu
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary tag-active':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-next-month',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-next-month',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.lastmonth
                                                                ? prospect_count?.lastmonth
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            to="/followup/need-follow-up"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-need-follow-up',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Perlu Follow Up
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary tag-active':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-need-follow-up',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-need-follow-up',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.need_followup
                                                                ? prospect_count?.need_followup
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="
                                isRole(driverJemput) && tier == 'enterprise'
                            "
                        >
                            <router-link
                                to="/pickup-driver"
                                :class="{
                                    '': true,
                                    active: this.$route.name == 'pickup-driver',
                                }"
                            >
                                <i class="pi pi-tablet"></i>
                                <span>Driver Jemput</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="
                                isRole(dealOrder) &&
                                (tier == 'gold' ||
                                    tier == 'platinum' ||
                                    tier == 'enterprise')
                            "
                        >
                            <router-link
                                to="/deal-order"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'deal-order' ||
                                        this.$route.name == 'deal-order-detail',
                                }"
                            >
                                <i class="pi pi-thumbs-up"></i>
                                <span>Deal Order</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="
                                isRole(lostOrder) &&
                                (tier == 'gold' ||
                                    tier == 'platinum' ||
                                    tier == 'enterprise')
                            "
                        >
                            <router-link
                                to="/lost-order"
                                :class="{
                                    '': true,
                                    active: this.$route.name == 'lost-order',
                                }"
                            >
                                <i class="pi pi-ban"></i>
                                <span>Lost Order</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="
                                isRole(pesananWalkIn) && tier == 'enterprise'
                            "
                        >
                            <router-link
                                to="/walk-in"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'walk-in' ||
                                        this.$route.name == 'walk-in-create' ||
                                        this.$route.name == 'walk-in-detail' ||
                                        this.$route.name == 'walk-in-edit',
                                }"
                            >
                                <i class="pi pi-file"></i>
                                <span>Pesanan Walk In</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="isRole(tally) && tier == 'enterprise'"
                        >
                            <router-link
                                to="/tally/off-loading"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'tally-driver' ||
                                        this.$route.name ==
                                            'tally-off-loading' ||
                                        this.$route.name == 'tally-detail',
                                }"
                            >
                                <i class="pi pi-box"></i>
                                <span>Tally</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="
                                isRole(klaimPesanan) &&
                                (tier == 'gold' ||
                                    tier == 'platinum' ||
                                    tier == 'enterprise')
                            "
                        >
                            <router-link
                                to="/order/process"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'order-process' ||
                                        this.$route.name ==
                                            'order-process-detail' ||
                                        this.$route.name ==
                                            'order-process-create',
                                }"
                            >
                                <i class="pi pi-file"></i>
                                <span>Klaim Pesanan</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="
                                isRole(daftarPesanan) &&
                                (tier == 'silver' ||
                                    tier == 'gold' ||
                                    tier == 'platinum' ||
                                    tier == 'enterprise')
                            "
                        >
                            <router-link
                                to="/order/cashier"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'order-cashier' ||
                                        this.$route.name ==
                                            'order-cashier-create' ||
                                        this.$route.name ==
                                            'order-cashier-detail' ||
                                        this.$route.name == 'order-cashier-va',
                                }"
                            >
                                <i class="pi pi-file"></i>
                                <span>Daftar Pesanan</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-show="
                                isRole(tracking) &&
                                (tier == 'enterprise' || tier == 'platinum')
                            "
                        >
                            <router-link
                                to="/tracking"
                                :class="{
                                    '': true,
                                    active: this.$route.name == 'tracking',
                                }"
                            >
                                <i class="pi pi-map-marker"></i>
                                <span>Tracking</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-if="
                                isRole(daftarManifest) &&
                                (tier == 'platinum' || tier == 'enterprise')
                            "
                        >
                            <router-link
                                to="/manifest"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'manifest' ||
                                        this.$route.name == 'manifest-create' ||
                                        this.$route.name == 'manifest-edit' ||
                                        this.$route.name == 'manifest-detail',
                                }"
                            >
                                <i class="pi pi-book"></i>
                                <span>Daftar Manifest</span>
                            </router-link>
                        </li>

                        <li
                            class="sidebar-dropdown"
                            v-if="isRole(routeAndManagementLeadTime)"
                        >
                            <router-link
                                to="/route-and-lead"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'route-and-lead' ||
                                        this.$route.name ==
                                            'route-and-lead-add' ||
                                        this.$route.name ==
                                            'route-and-lead-edit',
                                }"
                            >
                                <i class="fa fa-clock-o"></i>
                                <span>Route Management & Lead Time</span>
                            </router-link>
                        </li>
                        <li class="sidebar-dropdown" v-if="isRole(pricing)">
                            <router-link
                                to="/pricing"
                                :class="{
                                    '': true,
                                    active: this.$route.name == 'pricing',
                                }"
                            >
                                <i class="fa fa-tags"></i>
                                <span>Pricing</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-if="isRole(manajemenUser)"
                        >
                            <router-link
                                to="/user-management"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'user-management' ||
                                        this.$route.name ==
                                            'user-management-add' ||
                                        this.$route.name ==
                                            'user-management-edit',
                                }"
                            >
                                <i class="fa fa-group"></i>
                                <span>Manajemen User</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-if="isRole(pembayaranCustomer)"
                        >
                            <router-link
                                to="/customer-payment"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                            'customer-payment' ||
                                        this.$route.name ==
                                            'customer-payment-detail',
                                }"
                            >
                                <i class="fa fa-money"></i>
                                <span>Pembayaran Customer</span>
                            </router-link>
                        </li>
                        <li
                            class="sidebar-dropdown"
                            v-if="isRole(pembayaranVendor)"
                        >
                            <router-link
                                to="/vendor-payment"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'vendor-payment' ||
                                        this.$route.name ==
                                            'vendor-payment-detail',
                                }"
                            >
                                <i class="fa fa-truck"></i>
                                <span>Pembayaran Vendor</span>
                            </router-link>
                        </li>
                    </ul>

                    <div class="powered">
                        <div class="powered-content">
                            <div class="mt-3 text-gray-dashboard size-13">
                                Powered By
                            </div>
                            <img
                                src="../assets/logo.svg"
                                width="130"
                                alt="logo"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <main class="page-content vh-100">
            <div class="container-fluid">
                <div class="pl-40 pr-40 position-relative pt-3">
                    <div class="nav-top-main">
                        <div class="d-flex align-items-center">
                            <h5
                                class="size-16 fw-semibold mb-0 capitalize d-none d-lg-block"
                            >
                                {{ welcomeMessage }}
                            </h5>
                            <div class="ms-auto">
                                <div class="d-flex align-items-center">
                                    <div class="dropdown">
                                        <div
                                            class="notifications toggle dd-trigger me-4 cursor-pointer"
                                        >
                                            <span
                                                class="count bg-yellow"
                                                id="notifications-count"
                                                >{{ totalNotif }}</span
                                            >
                                            <span
                                                class="fa fa-bell-o size-22"
                                            ></span>
                                        </div>
                                        <div class="dropdown-menu w-40">
                                            <div
                                                class="d-flex items-align-center justify-content-between mb-4"
                                            >
                                                <div
                                                    class="size-16 fw-semibold"
                                                >
                                                    Notifikasi
                                                </div>
                                                <a
                                                    v-if="
                                                        notif_all.length !== 0
                                                    "
                                                    href="javascript:void(0)"
                                                    class="text-primary"
                                                    @click="readNotif()"
                                                >
                                                    <u
                                                        >Tandai semua telah
                                                        dibaca</u
                                                    >
                                                </a>
                                            </div>
                                            <div
                                                v-bind:class="{
                                                    'scroll-notif':
                                                        notif_all.length > 4,
                                                }"
                                            >
                                                <a
                                                    href="javascript:void(0)"
                                                    v-for="notif in notif_all"
                                                    :key="notif.id"
                                                    @click="
                                                        readNotif(
                                                            notif.id,
                                                            notif.meta.path
                                                        )
                                                    "
                                                >
                                                    <div
                                                        class="d-flex align-items-center mb-2"
                                                    >
                                                        <div class="me-3">
                                                            <img
                                                                class="user-notif"
                                                                :src="
                                                                    notif.status ===
                                                                    'pending'
                                                                        ? '../assets/bell-unread.png'
                                                                        : '../assets/bell-read.png'
                                                                "
                                                                alt="notification bell"
                                                            />
                                                        </div>
                                                        <div class="w-100">
                                                            <div
                                                                class="d-flex align-items-center justify-content-between"
                                                            >
                                                                <div
                                                                    class="size-16 fw-semibold"
                                                                    :class="
                                                                        notif.status ===
                                                                        'pending'
                                                                            ? 'title-unread'
                                                                            : 'text-gray'
                                                                    "
                                                                >
                                                                    {{
                                                                        notif.title
                                                                    }}
                                                                </div>
                                                                <div
                                                                    class="ms-auto text-gray size-12"
                                                                >
                                                                    {{
                                                                        notif.created_at
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                :class="
                                                                    notif.status ===
                                                                    'pending'
                                                                        ? 'subtitle-unread'
                                                                        : 'text-gray'
                                                                "
                                                            >
                                                                {{
                                                                    notif.subtitle
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div
                                                class="my-4"
                                                v-if="notif_all.length === 0"
                                            >
                                                <center>
                                                    <img
                                                        src="../assets/no-notif.png"
                                                        width="120"
                                                        alt=""
                                                    />
                                                    <div
                                                        class="mt-3 text-gray-dashboard"
                                                    >
                                                        Belum ada notifikasi
                                                    </div>
                                                </center>
                                            </div>
                                            <hr v-if="notif_all.length !== 0" />
                                            <div
                                                class="text-center"
                                                v-if="notif_all.length !== 0"
                                            >
                                                <router-link
                                                    to="/notification"
                                                    class="text-green"
                                                >
                                                    Lihat Semua Notifikasi
                                                    <i
                                                        class="fa fa-angle-right ms-2"
                                                    ></i>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dropdown-profile">
                                        <div
                                            class="toggle-profile cursor-pointer"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div class="me-2">
                                                    <template
                                                        v-if="
                                                            user.avatar != null
                                                        "
                                                    >
                                                        <div
                                                            class="user-avatar-sidebar user-small"
                                                            :style="{
                                                                'background-image':
                                                                    'url(' +
                                                                    user.avatar +
                                                                    ')',
                                                            }"
                                                        ></div>
                                                    </template>
                                                    <template v-else>
                                                        <div
                                                            class="user-avatar-sidebar user-small"
                                                            :style="{
                                                                'background-image':
                                                                    'url(' +
                                                                    require('../assets/user.jpg') +
                                                                    ')',
                                                            }"
                                                        ></div>
                                                    </template>
                                                </div>
                                                <div
                                                    class="fw-medium size-14"
                                                    style="line-height: 17px"
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                    >
                                                        <div class="capitalize">
                                                            {{ user.name }}
                                                        </div>
                                                        <div class="ms-2">
                                                            <i
                                                                class="fa fa-angle-down ms-2 size-16 fw-bold"
                                                            ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dropdown-menu-profile">
                                            <router-link
                                                :to="{ name: profileRole() }"
                                            >
                                                <a
                                                    class="size-14 fw-semibold text-dark"
                                                >
                                                    Edit Profile
                                                </a>
                                            </router-link>
                                            <hr />
                                            <a
                                                href="javascript:void(0)"
                                                @click="logout()"
                                                class="text-danger size-14 fw-semibold"
                                            >
                                                <i
                                                    class="fa fa-sign-out me-1"
                                                ></i>
                                                Log Out
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <router-view
                    class="mt-content"
                    :message="this.$attrs.message"
                    :totalNotif="totalNotif"
                    :roles="roles"
                />
            </div>
        </main>
    </div>
</template>

<script>
import Api from "../api/Api";
import $ from "jquery";
import { EventBus } from "../eventBus";
export default {
    components: {},
    data() {
        return {
            activeSubmenu: null,
            appName: "SILOG",
            isShowProfile: false,
            isShowProspect: false,
            user_id: "",
            user: {
                name: "",
            },
            roles: [],
            prospect_count: {},
            partnership_prospect_count: {},
            isMobile: false,
            notif_all: [],
            notif_trawlpack: [],
            notif_trawltruck: [],
            notif_trawlcarrier: [],
            totalNotif: 0,
            dashboardFinance: ["finance", "super-admin"],
            dashboardHeadDriver: ["head-driver", "super-admin"],
            dashboardTally: ["tally", "super-admin"],
            dashboardOpsManager: [
                "ops-manager",
                "super-admin",
                "vendor-management",
            ],
            dashboardCrmManager: ["crm-manager", "super-admin"],
            dashboardCrmSpecialist: [
                "crm-specialist-cs",
                "crm-specialist-telemarketer",
                "crm-specialist-sales",
                "admin-crm",
                "super-admin",
                "customer-service-analyst",
            ],
            dataLeads: [
                "crm-specialist-telemarketer",
                "crm-specialist-sales",
                "crm-specialist-cs",
                "admin-crm",
                "crm-manager",
                "customer-service-analyst",
                "super-admin",
            ],
            followUp: [
                "crm-specialist-telemarketer",
                "crm-specialist-sales",
                "crm-specialist-cs",
                "admin-crm",
                "crm-manager",
                "super-admin",
                "customer-service-analyst",
            ],
            headDriver: ["head-driver", "super-admin"],
            driverJemput: ["driver", "super-admin"],
            dealOrder: [
                "crm-specialist-telemarketer",
                "crm-specialist-sales",
                "crm-specialist-cs",
                "admin-crm",
                "crm-manager",
                "customer-service-analyst",
                "super-admin",
                "ops-manager",
            ],
            lostOrder: [
                "crm-specialist-telemarketer",
                "crm-specialist-sales",
                "crm-specialist-cs",
                "admin-crm",
                "crm-manager",
                "customer-service-analyst",
                "super-admin",
            ],
            pesananWalkIn: ["tally", "super-admin"],
            tally: ["tally", "super-admin"],
            klaimPesanan: [
                "crm-specialist-sales",
                "crm-specialist-cs",
                "admin-crm",
                "crm-manager",
                "super-admin",
            ],
            daftarPesanan: [
                "crm-specialist-telemarketer",
                "crm-specialist-sales",
                "ops-manager",
                "cashier",
                "crm-specialist-cs",
                "admin-crm",
                "crm-manager",
                "super-admin",
                "customer-service-analyst",
                "tracking",
            ],
            tracking: ["tracking", "super-admin"],
            daftarManifest: ["tracking", "ops-manager", "tally", "super-admin"],
            vendor: ["tracking", "super-admin", "vendor-management", "finance"],
            routeAndManagementLeadTime: [
                "crm-specialist-sales",
                "crm-specialist-cs",
                "admin-crm",
                "crm-manager",
                "super-admin",
                "ops-manager",
                "finance",
            ],
            pricing: [
                "crm-specialist-sales",
                "crm-specialist-cs",
                "admin-crm",
                "crm-manager",
                "super-admin",
                "finance",
            ],
            manajemenUser: ["super-admin"],
            pembayaranCustomer: ["finance", "super-admin"],
            pembayaranVendor: ["finance", "super-admin"],
            tier: localStorage.getItem("tier"),
        };
    },
    computed: {
        welcomeMessage() {
            if (this.$route.path.startsWith("/manifest")) {
                return "Manifest";
            } else if (this.$route.path.startsWith("/pricing")) {
                return "Pricing";
            } else if (this.$route.path.startsWith("/route-and-lead")) {
                return "Route Management & Lead Time";
            } else if (
                this.$route.path.startsWith("/vendor-management") ||
                this.$route.path.startsWith("/route")
            ) {
                return "Manajemen Vendor";
            } else if (this.$route.path.startsWith("/dashboard")) {
                return "Dashboard";
            } else if (this.$route.path.startsWith("/user-management")) {
                return "Manajemen User";
            } else {
                return `Selamat Datang, ${this.user.name}`;
            }
        },
    },
    created() {
        this.setAppName();
        this.getUserLogin();
        this.followupCount();
        // this.getPartnershipProspectCount();
        this.getNotif();
        this.getCountNotif();
        EventBus.$on("newNotification", this.getNotif);
    },
    beforeDestroy() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.onResize, {
                passive: true,
            });
        }
        EventBus.$off("newNotification", this.getNotif);
    },
    mounted() {
        $("#close-sidebar").click(function () {
            $(".page-wrapper").removeClass("toggled");
        });
        $("#show-sidebar").click(function () {
            $(".page-wrapper").addClass("toggled");
        });

        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });

        const $menu = $(".dropdown");

        const onMouseUp = (e) => {
            if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
                $menu.removeClass("is-active");
            }
        };

        $(".toggle").on("click", () => {
            $menu
                .toggleClass("is-active")
                .promise()
                .done(() => {
                    if ($menu.hasClass("is-active")) {
                        $(document).on("mouseup", onMouseUp);
                    } else {
                        $(document).off("mouseup", onMouseUp);
                    }
                });
        });

        const $menu_profile = $(".dropdown-profile");

        const onMouseUpProfile = (e) => {
            if (
                !$menu_profile.is(e.target) &&
                $menu_profile.has(e.target).length === 0
            ) {
                $menu_profile.removeClass("is-active-profile");
            }
        };

        $(".toggle-profile").on("click", () => {
            $menu_profile
                .toggleClass("is-active-profile")
                .promise()
                .done(() => {
                    if ($menu_profile.hasClass("is-active-profile")) {
                        $(document).on("mouseup", onMouseUpProfile);
                    } else {
                        $(document).off("mouseup", onMouseUpProfile);
                    }
                });
        });
    },
    methods: {
        toggleSubmenu(routeName) {
            this.activeSubmenu =
                this.activeSubmenu === routeName ? null : routeName;
        },
        setAppName() {
            let appName = process.env.VUE_APP_APP_NAME;
            if (appName) {
                this.appName = appName;
            }
        },
        onResize() {
            this.isMobile = window.innerWidth < 992;
        },
        profileRole() {
            return "crm-profile";
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/auth/account`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.user = res.data.data;
                    this.user_id = res.data.data.hash;
                    this.roles = res.data.data.roles;
                    localStorage.setItem("roles", JSON.stringify(this.roles));
                    localStorage.setItem("user", JSON.stringify(this.user));
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        followupCount() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/followup/count`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.prospect_count = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // getPartnershipProspectCount() {
        //     Api.get(
        //         `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/prospect/count`,
        //         {
        //             headers: {
        //                 Authorization:
        //                     "Bearer " + localStorage.getItem("token"),
        //             },
        //         }
        //     )
        //         .then((res) => {
        //             this.partnership_prospect_count = res.data.data;
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         });
        // },
        logout() {
            const payload = {
                token: localStorage.getItem("fcmToken"),
                device_id: localStorage.getItem("deviceId"),
                type: "remove",
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/user/notification/sync`,
                payload,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.$toast.success("Logout Berhasil", {
                        position: "top-right",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: false,
                        icon: true,
                        rtl: false,
                    });

                    localStorage.clear();

                    this.$router.push({ path: "/" });
                })
                .catch((err) => {
                    console.error(
                        "Error syncing notifications during logout:",
                        err
                    );
                    localStorage.clear();
                    this.$router.push({ path: "/" });
                });
        },
        getNotif() {
            var payload = {
                limit: 10,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/user/notification`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    let data = res.data.data.list_data;
                    this.notif_all = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getCountNotif() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/user/notification/count`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.totalNotif = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        readNotif(id = null, path = null) {
            if (path && !path.startsWith("/")) {
                path = "/" + path;
            }
            let data = id ? { id: id } : null;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/user/notification/read`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.getNotif();
                    if (path) {
                        this.$router.push(path);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        // readAllNotif() {
        //     Api.post(
        //         `${process.env.VUE_APP_SERVICE_URL}/notification/user/update_all`,
        //         "",
        //         {
        //             headers: {
        //                 Authorization: "Bearer" + localStorage.getItem("token"),
        //             },
        //         }
        //     )
        //         .then((res) => {
        //             console.log(res);
        //             this.getNotif();
        //             this.getCountNotif();
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // },
        // postNotif() {
        //     var data = {
        //         prefix: "users",
        //         id: this.user_id,
        //         token: localStorage.token,
        //         platform: "web",
        //         deviceId: "unique2",
        //         type: "logout",
        //     };
        //     Api.post(`/cb/fcm/update`, data, {
        //         headers: {
        //             Authorization: "Bearer" + localStorage.getItem("token"),
        //         },
        //     })
        //         .then((res) => {
        //             console.log(res);
        //             setTimeout(() => {
        //                 this.$router.push({ path: "/" });
        //             }, 1500);
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // },
    },
};
</script>

<style scoped>
@keyframes swing {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(10deg);
    }
    30% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(0deg);
    }
    60% {
        transform: rotate(5deg);
    }
    70% {
        transform: rotate(0deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes sonar {
    0% {
        transform: scale(0.9);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.page-wrapper {
    height: 100vh;
}
.page-wrapper .theme {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
    margin: 2px;
}
.page-wrapper .theme.chiller-theme {
    background: #1e2229;
}
/*----------------toggeled sidebar----------------*/
.page-wrapper.toggled .sidebar-wrapper {
    left: 0px;
}
@media screen and (min-width: 768px) {
    .page-wrapper.toggled .page-content {
        padding-left: 260px;
    }
}
#show-sidebar {
    position: fixed;
    left: 0;
    top: 10px;
    padding: 1px;
    font-size: 20px;
    border-radius: 0 20px 20px 0px;
    width: 35px;
    transition-delay: 0.3s;
    z-index: 10;
}
.page-wrapper.toggled #show-sidebar {
    left: -40px;
}
.sidebar-wrapper {
    width: 260px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
}
.sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.sidebar-wrapper a {
    text-decoration: none;
}
.sidebar-content {
    max-height: calc(100% - 30px);
    height: calc(100% - 30px);
    overflow-y: auto;
    /* position: relative; */
    padding: 15px;
    padding-bottom: 5rem !important;
    background-color: #f9fafb;
}
.sidebar-content.desktop {
    overflow-y: hidden;
}
/* .sidebar-wrapper .sidebar-brand {
    padding: 10px 20px;
    display: flex;
    align-items: center;
} */
.sidebar-wrapper .sidebar-brand > a {
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
}
.sidebar-wrapper .sidebar-brand #close-sidebar {
    cursor: pointer;
    font-size: 20px;
}
/*--------------------sidebar-header----------------------*/
.sidebar-wrapper .sidebar-header {
    padding: 20px 5px;
    overflow: hidden;
}
.sidebar-wrapper .sidebar-header .user-info > span {
    display: block;
}
.sidebar-wrapper .sidebar-header .user-info .user-role {
    font-size: 12px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status {
    font-size: 11px;
    margin-top: 4px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status i {
    font-size: 8px;
    margin-right: 4px;
    color: #5cb85c;
}
/*-----------------------sidebar-search------------------------*/
.sidebar-wrapper .sidebar-search > div {
    padding: 10px 20px;
}
/*----------------------sidebar-menu-------------------------*/
.sidebar-wrapper .sidebar-menu {
    padding-bottom: 10px;
}
.sidebar-wrapper .sidebar-menu .header-menu span {
    font-weight: 600;
    font-size: 14px;
    padding: 15px 20px 5px 20px;
    display: inline-block;
}
.sidebar-wrapper .sidebar-menu ul li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    padding: 8px 0px 8px 4px;
}
.sidebar-wrapper .sidebar-menu ul li a i {
    margin-right: 4px;
    font-size: 16px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
}
.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
    display: inline-block;
    animation: swing ease-in-out 0.5s 1 alternate;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    padding: 5px 0;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
    padding-left: 10px;
    font-size: 13px;
}
.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
    float: right;
    margin-top: 8px;
    margin-left: 5px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
    float: right;
    margin-top: 0px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
    transform: rotate(90deg);
    right: 17px;
}
.sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
}
.sidebar-footer > a {
    flex-grow: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    position: relative;
    color: #3782e6;
    font-weight: 600;
    font-size: 16px;
}
.sidebar-footer > a .notification {
    position: absolute;
    top: 0;
}
.badge-sonar {
    display: inline-block;
    background: #980303;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 0;
}
.badge-sonar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #980303;
    opacity: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: sonar 1.5s infinite;
}
/*--------------------------page-content-----------------------------*/
.page-wrapper .page-content {
    display: inline-block;
    width: 100%;
    padding-left: 0px;
    /* padding-top: 20px; */
}
.page-wrapper .page-content > div {
    /* padding: 20px 40px; */
    padding-right: 0px;
    padding-left: 0px;
}
.page-wrapper .page-content {
    overflow-x: hidden;
}
.chiller-theme .sidebar-wrapper {
    background: #fff;
    box-shadow: 0 0px 30px 0 rgb(144 30 44 / 5%);
}
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a {
    color: #84919d;
    font-weight: 400;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a.active {
    color: #3782e6;
    background: #e8e7ff;
    border-radius: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
    /* padding: 5px 4px; */
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a.active::before {
    position: absolute;
    background-color: #3782e6;
    block-size: 2rem;
    border-end-start-radius: 0.375rem;
    border-start-start-radius: 0.375rem;
    content: "";
    inline-size: 0.25rem;
    inset-inline: -0.8rem;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a.sub.active::before {
    content: none;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a:hover {
    background: #e8e7ff;
    border-radius: 10px;
}
.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #bdbdbd;
}
.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}
.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #6c7b88;
}
.chiller-theme .sidebar-footer {
    color: #3782e6;
    box-shadow: 0 0px 30px 0 rgb(144 30 44 / 5%);
    padding: 5px;
    background: #fff;
}
.chiller-theme .sidebar-footer > a:first-child {
    border-left: none;
}
.chiller-theme .sidebar-footer > a:last-child {
    border-right: none;
}
#close-sidebar {
    /* background: #3782e6; */
    /* color: #fff !important; */
    /* box-shadow: 0 0.125rem 0.25rem rgb(219 83 99 / 10%); */
    width: 30px;
    height: 30px;
    /* border-radius: 50px; */
    text-align: center;
    position: absolute;
    right: 20px;
    top: 11px;
    z-index: 99999;
}
.radius {
    border-radius: 50px;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a.active.sub {
    border-left: none;
    color: #fff;
    background: #3782e6;
    padding: 6px 8px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px 0px rgba(115, 103, 240, 0.3);
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a.sub {
    padding: 8px 8px 8px 5px;
}
.sidebar-wrapper .sidebar-menu ul li a i.sub {
    margin-right: 0px;
    font-size: 12px;
}
.block {
    display: block;
}
.none {
    display: none;
}
.user-avatar-sidebar {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 2px solid #f8f9fa;
}
.edit-position {
    position: absolute;
    right: -10px;
    color: #dc3545;
    background: #fff;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    box-shadow: 0 2px 5px rgb(196 205 213 / 25%);
    text-align: center;
    padding: 1px;
}
.nav-top-main {
    background: #f9fafb;
    box-shadow: 0 0px 30px 0 rgb(144 30 44 / 5%);
    padding: 10px 18px;
    width: 100%;
    z-index: 9;
    position: fixed;
    top: 0;
}

.user-small {
    width: 35px !important;
    height: 35px !important;
}
.mt-nav-top {
    margin-top: 50px;
}
.profile-box-menu {
    background: #fff;
    padding: 15px !important;
    width: 180px;
    position: fixed;
    z-index: 9999;
    right: 25px;
    top: 70px;
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border-top-left-radius: 0px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.hr-horizontal {
    background: rgba(0, 0, 0, 0);
    border: 0;
    height: 1px;
    color: inherit;
    opacity: 0.25;
    margin: 0.5rem 0;
    background-image: linear-gradient(
        90deg,
        transparent,
        rgba(0, 0, 0, 0.4),
        transparent
    );
}
.hidden {
    display: none;
}
.show {
    display: block !important;
}
.p-alert-notif {
    padding: 5px;
    left: 6px !important;
    top: 2px !important;
}
.count {
    position: absolute;
    top: -6px;
    right: 15px;
    height: 15px;
    width: 15px;
    background: #e60013;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    font-size: 9px;
    font-weight: bold;
    z-index: 99;
    line-height: 15px;
}
.grayscale {
    filter: grayscale(1);
    /* background-color: #e0e0e0; */
    background-blend-mode: overlay;
}
.user-notif {
    width: 40px !important;
    height: 40px !important;
}
.scroll-notif {
    height: 240px;
    overflow-y: scroll;
}
.dropdown {
    position: relative;
}
.dropdown-menu {
    display: none;
}
.is-active .dropdown-menu {
    display: block;
    background: #fff;
    padding: 15px !important;
    width: auto;
    position: fixed;
    min-width: 380px;
    z-index: 9999;
    right: 25px;
    top: 70px;
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border-radius: 12px;
}
.dropdown-profile {
    position: relative;
}
.dropdown-menu-profile {
    display: none;
}
.is-active-profile .dropdown-menu-profile {
    display: block;
    background: #fff;
    padding: 15px !important;
    width: 180px;
    position: fixed;
    z-index: 9999;
    right: 25px;
    top: 70px;
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border-radius: 12px;
}
.mt-content {
    margin-top: 20px;
}
.tag-active {
    color: #3782e6;
    background: #fff;
    padding-bottom: 2px;
    margin-bottom: 0px;
}
@media (min-width: 992px) {
    .nav-top-main {
        background: #f9fafb;
        box-shadow: 0 0px 30px 0 rgb(144 30 44 / 5%);
        padding: 10px 40px;
        width: calc(100% - 1rem * 2 - 16.25rem);
        z-index: 99;
        position: fixed;
        top: 0;
    }
}
.powered {
    position: absolute;
    bottom: 2%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}
.powered:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.79) 0, #fff);
    z-index: 0;
    filter: blur(8px);
}
.powered-content {
    position: relative;
    z-index: 999;
    text-align: center;
}
.title-unread {
    color: #329a5f !important;
}
.subtitle-unread {
    color: #3e4e5c !important;
}
.w-40 {
    width: 40rem !important;
}
.bg-yellow {
    background: #ff8600 !important;
}
</style>

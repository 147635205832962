<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16 cursor-pointer"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-3">
            <div class="table-responsive">
                <table class="w-100" cellpadding="10">
                    <tr class="border-bottom">
                        <td class="fw-medium ps-0">Order ID</td>
                        <td>:</td>
                        <td class="fw-bold text-end pe-0">
                            {{ data?.information_order?.order_id }}
                        </td>
                    </tr>
                    <tr class="border-bottom">
                        <td class="fw-medium ps-0">Total Pembayaran</td>
                        <td>:</td>
                        <td class="pe-0">
                            <div
                                class="d-flex align-items-center text-green justify-content-end"
                            >
                                <div
                                    @click="copyTotalPayment"
                                    v-if="!showCopiedPaymentNotification"
                                    class="cursor-pointer fw-bold"
                                >
                                    {{ currency(data?.payment?.total_payment) }}
                                    <img
                                        src="../assets/copy.svg"
                                        alt="copy"
                                        class="ms-2"
                                    />
                                </div>
                                <div v-else class="text-green text-center ms-2">
                                    Copied!
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="fw-medium ps-0">Metode Pembayaran</td>
                        <td>:</td>
                        <td class="pe-0">
                            <div
                                class="d-flex align-items-center justify-content-end fw-bold"
                            >
                                <img
                                    :src="data?.payment?.asset"
                                    alt="va icon"
                                    width="50"
                                    class="me-2"
                                />
                                {{ data?.payment?.channel }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" class="px-0">
                            <div class="d-flex justify-content-end">
                                <button
                                    class="btn btn-change d-flex align-items-center"
                                    @click="openNewVaModal"
                                >
                                    <img
                                        src="../assets/change.svg"
                                        alt="change"
                                        class="me-2"
                                    />
                                    Generate New Virtual Account
                                </button>
                            </div>
                            <div class="box mt-3">
                                <p class="text-gray text-center mb-0 fw-medium">
                                    Nomor Virtual Account
                                </p>
                                <div class="d-flex justify-content-center my-2">
                                    <div
                                        @click="copyVirtualAccount"
                                        class="cursor-pointer d-flex align-items-center"
                                        v-if="!showCopiedVANotification"
                                    >
                                        <h5 class="text-primary mb-0 me-2">
                                            {{ data?.payment?.va }}
                                        </h5>
                                        <img
                                            src="../assets/copy-2.svg"
                                            alt="copy 2"
                                        />
                                    </div>
                                    <h5 v-else class="text-primary ms-2">
                                        Copied!
                                    </h5>
                                </div>
                                <p class="text-gray fw-bold text-center mb-0">
                                    PT Klik Logistics Putera Harmas
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-2 ps-0">Waktu Pembayaran</td>
                        <td class="pb-2">:</td>
                        <td class="pb-2 pe-0">
                            <h5 class="mb-0 text-yellow fw-bold text-end">
                                {{ timeCount }}
                            </h5>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" class="pt-0 px-0">
                            <p class="mb-0 text-end">
                                Jatuh tempo
                                <span class="fw-bold">{{
                                    formattedDueDate
                                }}</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-0 px-0" colspan="3">
                            <div class="d-flex align-items-center">
                                <img
                                    src="../assets/home-va.svg"
                                    alt="home va"
                                />
                                <h5 class="mb-0 ms-2">
                                    Pembayaran Melalui Virtual Account
                                </h5>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="box-manifest mt-3">
            <div class="d-flex align-items-center mb-4">
                <img src="../assets/document.svg" alt="document" class="me-2" />
                <h5 class="mb-0">Petunjuk Pembayaran</h5>
            </div>
            <div
                v-for="(instruction, index) in instructions"
                :key="index"
                class="instruction-item"
            >
                <div
                    class="d-flex cursor-pointer justify-content-between align-items-center"
                    @click="toggleDescription(index)"
                >
                    <p class="mb-0 fw-bold">{{ instruction.title }}</p>
                    <img
                        src="../assets/arrow-down.svg"
                        alt="arrow down"
                        :class="{ 'rotate-arrow': expandedIndex === index }"
                    />
                </div>
                <p
                    v-if="expandedIndex === index"
                    class="mt-2"
                    v-html="instruction.description"
                ></p>
                <hr v-if="index < instructions.length - 1" />
            </div>
        </div>
        <div class="modal-vue" v-if="modalNewVa">
            <div class="overlay" @click="modalNewVa = false"></div>
            <div class="modal-body-chat vsm">
                <h5 class="fw-bold mb-4">Generate New Virtual Account</h5>
                <label class="mb-2"
                    >Bank Pembayaran Sebelumnya
                    <span class="text-danger">*</span></label
                >
                <div class="d-flex align-items-center form-control mb-4">
                    <img
                        :src="data?.payment?.asset"
                        alt="va icon"
                        width="50"
                        class="me-2"
                    />
                    {{ data?.payment?.channel }}
                </div>
                <label
                    >Bank Pembayaran <span class="text-danger">*</span></label
                >
                <p class="text-gray size-12 mb-2">
                    Pilih bank untuk membuat Virtual Account baru
                </p>
                <div
                    class="form-control cursor-pointer"
                    :class="{ 'mb-4': !isDropdownOpen }"
                    @click="isDropdownOpen = !isDropdownOpen"
                >
                    <div
                        class="d-flex justify-content-between align-items-center w-100"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                v-if="selectedBank"
                                :src="selectedBank.asset"
                                :alt="selectedBank.asset"
                                width="50"
                                class="me-2"
                            />
                            <p
                                class="mb-0"
                                :class="{
                                    'text-gray': !selectedBank,
                                }"
                            >
                                {{
                                    selectedBank
                                        ? selectedBank.label
                                        : "Pilih bank pembayaran"
                                }}
                            </p>
                        </div>
                        <img
                            src="../assets/arrow-down-2.svg"
                            alt="arrow down 2"
                            :class="{ 'rotate-arrow': isDropdownOpen }"
                        />
                    </div>
                </div>
                <div v-if="isDropdownOpen" class="form-control my-2">
                    <div
                        v-for="(channel, index) in channels"
                        :key="index"
                        class="d-flex justify-content-center flex-column py-2 cursor-pointer"
                        :class="{
                            'border-bottom': index !== channels.length - 1,
                        }"
                        @click="selectBank(channel)"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                :src="channel.asset"
                                :alt="channel.asset"
                                width="50"
                                class="me-2"
                            />
                            {{ channel.label }}
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end" v-if="!isDropdownOpen">
                    <button
                        @click="generateConfirmVa()"
                        class="btn btn-generate px-5"
                        :disabled="!selectedBank"
                    >
                        Generate
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirmNewVa">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">Nomor VA baru akan dibuat</h5>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin ingin membuat Nomor VA yang
                        baru?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="cancelConfirmVa()"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="generateNewVa()"
                            :disabled="isLoading"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isLoading"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";

export default {
    data() {
        return {
            expandedIndex: null,
            data: null,
            isPageLoading: false,
            showCopiedPaymentNotification: false,
            showCopiedVANotification: false,
            timeCount: "24 Jam : 0 Menit : 0 Detik",
            dueDate: null,
            countdownTimer: null,
            modalNewVa: false,
            channels: [],
            isDropdownOpen: false,
            selectedBank: null,
            modalConfirmNewVa: false,
            isLoading: false,
            instructions: [],
        };
    },
    computed: {
        formattedDueDate() {
            if (!this.dueDate) return "";

            const months = [
                "Januari",
                "Februari",
                "Maret",
                "April",
                "Mei",
                "Juni",
                "Juli",
                "Agustus",
                "September",
                "Oktober",
                "November",
                "Desember",
            ];

            const day = this.dueDate.getDate().toString().padStart(2, "0");
            const month = months[this.dueDate.getMonth()];
            const year = this.dueDate.getFullYear();
            const hours = this.dueDate.getHours().toString().padStart(2, "0");
            const minutes = this.dueDate
                .getMinutes()
                .toString()
                .padStart(2, "0");
            const seconds = this.dueDate
                .getSeconds()
                .toString()
                .padStart(2, "0");

            return `${day} ${month} ${year} | ${hours}:${minutes}:${seconds}`;
        },
    },
    created() {
        this.getDetail();
        this.startCountdown();
    },
    methods: {
        toggleDescription(index) {
            if (this.expandedIndex === index) {
                this.expandedIndex = null;
            } else {
                this.expandedIndex = index;
            }
        },
        generateNewVa() {
            this.isLoading = true;
            var data = {
                receipt_code: this.$route.params.id,
                va: this.selectedBank.va,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/order/generate_va`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalConfirmNewVa = false;
                    this.getDetail();
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirmNewVa = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        cancelConfirmVa() {
            this.modalConfirmNewVa = false;
            this.modalNewVa = true;
        },
        generateConfirmVa() {
            this.modalNewVa = false;
            this.modalConfirmNewVa = true;
        },
        selectBank(channel) {
            this.selectedBank = channel;
            this.isDropdownOpen = false;
        },
        openNewVaModal() {
            this.modalNewVa = true;
            this.selectedBank = null;
            this.getChannels();
        },
        startCountdown() {
            if (this.countdownTimer) {
                clearInterval(this.countdownTimer);
            }
            if (!this.dueDate) return;
            this.countdownTimer = setInterval(() => {
                const now = new Date();
                const difference = this.dueDate.getTime() - now.getTime();

                if (difference <= 0) {
                    clearInterval(this.countdownTimer);
                    this.timeCount = "0 Jam : 0 Menit : 0 Detik";
                    return;
                }

                const hours = Math.floor(difference / (1000 * 60 * 60));
                const minutes = Math.floor(
                    (difference % (1000 * 60 * 60)) / (1000 * 60)
                );
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                this.timeCount = `${hours} Jam : ${minutes} Menit : ${seconds} Detik`;
            }, 1000);
        },
        getDetail() {
            this.isPageLoading = true;
            var payload = {
                order_id: this.$route.params.id,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order/detail`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.data = res.data.data;
                    if (this.data.payment.status === "success") {
                        this.$router.push("/order/cashier");
                    }
                    if (this.data?.payment?.expired_at) {
                        this.dueDate = new Date(this.data.payment.expired_at);
                        this.startCountdown();
                    }

                    this.getInstructions(this.data.payment.va_type);

                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.isPageLoading = false;
                });
        },
        getInstructions(vaType) {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/payment/instruction`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.instructions = res.data.data
                        .filter((instruction) =>
                            Object.keys(instruction).includes(vaType)
                        )
                        .map((instruction) => instruction[vaType])
                        .flat();
                    console.log(this.instructions);
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getChannels() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/payment/channel`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.channels = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        copyVirtualAccount() {
            const virtualAccount = this.data?.payment?.va;
            if (!virtualAccount) return;

            const vaString = virtualAccount.toString();
            if (navigator.clipboard) {
                navigator.clipboard
                    .writeText(vaString)
                    .then(() => {
                        this.showCopiedVANotification = true;
                        setTimeout(() => {
                            this.showCopiedVANotification = false;
                        }, 1000);
                    })
                    .catch((err) => {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err,
                        });
                    });
            } else {
                const textArea = document.createElement("textarea");
                textArea.value = vaString;
                document.body.appendChild(textArea);
                textArea.select();
                try {
                    document.execCommand("copy");
                    this.showCopiedVANotification = true;
                    setTimeout(() => {
                        this.showCopiedVANotification = false;
                    }, 1000);
                } catch (err) {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err,
                    });
                }

                document.body.removeChild(textArea);
            }
        },
        copyTotalPayment() {
            const totalPayment = this.data?.payment?.total_payment;
            if (!totalPayment) return;
            const paymentString = totalPayment.toString();
            if (navigator.clipboard) {
                navigator.clipboard
                    .writeText(paymentString)
                    .then(() => {
                        this.showCopiedPaymentNotification = true;
                        setTimeout(() => {
                            this.showCopiedPaymentNotification = false;
                        }, 1000);
                    })
                    .catch((err) => {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err,
                        });
                    });
            } else {
                const textArea = document.createElement("textarea");
                textArea.value = paymentString;
                document.body.appendChild(textArea);
                textArea.select();
                try {
                    document.execCommand("copy");
                    this.showCopiedPaymentNotification = true;
                    setTimeout(() => {
                        this.showCopiedPaymentNotification = false;
                    }, 1000);
                } catch (err) {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err,
                    });
                }

                document.body.removeChild(textArea);
            }
        },
    },
    beforeUnmount() {
        if (this.countdownTimer) {
            clearInterval(this.countdownTimer);
        }
    },
};
</script>

<style lang="scss" scoped>
.btn-generate:disabled {
    background-color: #e0e6eb !important;
    color: #849bae !important;
}
.btn-generate {
    background-color: #1291ff !important;
    color: #fafcff !important;
}
.text-gray {
    color: #a3b4c2 !important;
}
.modal-vue .modal-body-chat.vsm {
    width: 450px;
}
.rotate-arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}
hr {
    color: #c1cdd7 !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.btn-change {
    background-color: #cce5ff;
    color: #006dcc;
    border: none !important;
}
.btn-change:hover {
    background-color: #cce5ff;
    color: #006dcc;
    border: none !important;
}
.btn-change:focus {
    background-color: #cce5ff !important;
    color: #006dcc !important;
    border: none !important;
}
</style>

<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Tambah Vendor</h5>
            <div class="row">
                <div class="col-lg-6 mb-4">
                    <label class="mb-2"
                        >Nama Vendor <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama vendor"
                        v-model="detailVendor.name"
                    />
                </div>
                <div class="col-lg-6 mb-4">
                    <label class="mb-2"
                        >Nomor Telepon <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nomor telepon"
                        v-model="detailVendor.phone"
                    />
                </div>
                <div class="col-lg-6 mb-4">
                    <label class="mb-2"
                        >Email <span class="text-yellow">*</span></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        placeholder="Masukkan email"
                        v-model="detailVendor.email"
                    />
                </div>
                <div class="col-md-12 mb-4">
                    <label class="mb-2"
                        >Alamat Vendor <span class="text-yellow">*</span></label
                    >
                    <textarea
                        class="form-control"
                        placeholder="Masukkan alamat vendor"
                        v-model="detailVendor.address"
                    ></textarea>
                </div>
            </div>
            <hr />
            <h5 class="fw-bold mb-4">Informasi PIC Vendor</h5>
            <div class="row">
                <div class="col-lg-6 mb-4">
                    <label class="mb-2"
                        >Nama PIC Vendor
                        <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama PIC vendor"
                        v-model="detailVendor.pic"
                    />
                </div>
                <div class="col-lg-6 mb-4">
                    <label class="fw-bold mb-2">
                        Nomor Telepon PIC Vendor
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nomor telepon PIC"
                            @input="validatePhone()"
                            v-model="detailVendor.phone_pic"
                        />
                    </div>
                </div>
            </div>
            <hr />
            <h5 class="fw-bold mb-4">Informasi Nomor Rekening Vendor</h5>
            <div class="row">
                <div class="col-lg-4 mb-4 position-relative">
                    <label class="mb-2"
                        >Nama Bank <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="detailVendor.bank_name"
                        :options="bankNames"
                        label="name"
                        track-by="name"
                        placeholder="Pilih bank"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-4 mb-4 position-relative">
                    <label class="mb-2"
                        >Nomor Rekening
                        <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nomor rekening"
                        v-model="detailVendor.account_number"
                    />
                </div>
                <div class="col-lg-4 mb-4 position-relative">
                    <label class="mb-2"
                        >Nama Pemilik Rekening
                        <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama pemilik rekening"
                        v-model="detailVendor.account_name"
                    />
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button
                    class="btn btn-primary px-4"
                    :disabled="
                        !detailVendor.name ||
                        !detailVendor.phone ||
                        !detailVendor.email ||
                        !detailVendor.address ||
                        !detailVendor.pic ||
                        !detailVendor.phone_pic ||
                        !detailVendor.bank_name ||
                        !detailVendor.account_number ||
                        !detailVendor.account_name
                    "
                    @click="modalConfirm = true"
                >
                    Simpan Perubahan
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h5 class="mb-0 fw-bold mt-4">
                        Perubahan data vendor akan disimpan
                    </h5>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="editData()"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h5 class="mb-0 fw-bold mt-4">
                        Perubahan data vendor berhasil disimpan
                    </h5>
                </div>
                <router-link
                    to="/vendor-management"
                    href="javascript:void(0)"
                    class="btn btn-primary w-100 btn-lg mt-4"
                >
                    Tutup
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import magnifierDisabled from "../assets/magnifier-disabled.svg";
import magnifier from "../assets/magnifier.svg";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            isPageLoading: false,
            magnifierIcon: magnifierDisabled,
            magnifierActiveIcon: magnifier,
            modalConfirm: false,
            modalSuccess: false,
            isDropdownOpen: false,
            detailVendor: {
                name: null,
                phone: null,
                email: null,
                address: null,
                pic: null,
                phone_pic: null,
                bank_name: null,
                account_number: null,
                account_name: null,
            },
            isLoading: false,
            bankNames: [],
        };
    },
    created() {
        this.getBanks();
    },
    methods: {
        getBanks() {
            this.isPageLoading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/bank`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.bankNames = res.data.data.map((bank) => {
                        return { name: bank };
                    });
                    this.getDetailVendor();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDetailVendor() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vendors/detail`, {
                params: {
                    vendor_id: this.$route.params.id,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    const data = res.data.data;
                    this.detailVendor.name = data.name;
                    this.detailVendor.phone = data.phone;
                    this.detailVendor.email = data.email;
                    this.detailVendor.address = data.address;
                    this.detailVendor.pic = data.pic;
                    this.detailVendor.phone_pic = data.phone_pic;
                    this.detailVendor.bank_name = data.bank_name;
                    this.detailVendor.account_number = data.account_number;
                    this.detailVendor.account_name = data.account_name;
                    this.detailVendor.bank_name = this.bankNames.find(
                        (r) => r.name === data?.bank_name
                    );
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        editData() {
            this.isLoading = true;
            var data = {
                vendor_id: this.$route.params.id,
                name: this.detailVendor.name,
                phone: this.detailVendor.phone,
                email: this.detailVendor.email,
                address: this.detailVendor.address,
                pic: this.detailVendor.pic,
                phone_pic: this.detailVendor.phone_pic,
                bank_name: this.detailVendor.bank_name.name,
                account_number: this.detailVendor.account_number,
                account_name: this.detailVendor.account_name,
            };

            Api.post(`${process.env.VUE_APP_SERVICE_URL}/vendors/edit`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then(() => {
                    this.isLoading = false;
                    this.modalSuccess = true;
                    this.modalConfirm = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.outline-add {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
}
.rotate-180 {
    transform: rotate(180deg);
}
.transition-transform {
    transition: transform 0.3s ease;
}
.outline-yellow {
    background-color: transparent !important;
    border: 1px solid #ff8600 !important;
    color: #ff8600 !important;
}
.b-l-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
::v-deep .multi-form-custom.magnifier {
    .multiselect__tags {
        padding-left: 40px !important;
    }
    .multiselect__select {
        padding: 0;
    }
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    cursor: pointer;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.bb-r {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
</style>
